// import logo from "./logo.svg";
import { BrowserRouter, Navigate, useNavigate } from "react-router-dom";
import "./App.css";
import Index from "./routes/index";
import React, { useCallback, useEffect, useState } from "react";

import i18 from "i18next";
import { initReactI18next, I18nextProvider } from "react-i18next";
import { useTranslation } from "react-i18next";
import en from "./shared/localization/en.json";
import ne from "./shared/localization/ne.json";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getToken, onMessage } from 'firebase/messaging';
import Test from '../src/assets/images/avtar.png';
import { messaging } from './firebase/firebaseConfig';
import { CustomNotificationPayload } from './types/NotificationPayload';



//Local language imported files
const resources: any = {
  en: { translation: en },
  ne: { translation: ne },
};

//Set Initial language to application
i18.use(initReactI18next).init({
  resources,
  compatibilityJSON: "v3",
  lng: "en", //Set default language
  fallbackLng: "en",
  debug: true,
  interpolation: {
    escapeValue: false,
  },
});



function App() {
  const [token, setToken] = useState<string | null>(null);

  const requestPermission = async () => {
    const permission = await Notification.requestPermission();
    console.log('Notification permission status:', permission);
    if (permission === 'granted') {
      try {
        // Ensure messaging is not null before calling getToken
        if (messaging) {
          const currentToken = await getToken(messaging, {
            vapidKey: 'BCQ_lmwV_NImIinmMbUan1RpOwG2gp3-NmHFGU_GOHDmcb2tL9qbbOPfwKYMUKWgGzvwiOLSK08V9dle9p6npRM', // Replace with your VAPID key
          });
          if (currentToken) {
            console.log('Token generated:', currentToken);
            setToken(currentToken);
          } else {
            console.log('No registration token available.');
          }
        }
      } catch (error) {
        console.error('An error occurred while retrieving token:', error);
      }
    }
  };

  useEffect(() => {
    // Request permission and fetch token
    requestPermission();

    // Only setup message listener if messaging is not null
    if (messaging) {
      // Handle foreground messages
      onMessage(messaging, (payload: CustomNotificationPayload) => {
        console.log('Message received:', payload);
        const notification = payload.notification;
        toast(<div>
          <strong>{notification?.title || 'Notification'}</strong>
          <p>{notification?.body}</p>
          {notification?.icon && <img src={notification.icon} alt="Notification icon" />}
        </div>);
      });
    }
  }, []);



  return (

    <div className="App">
      <I18nextProvider i18n={i18}>
        <BrowserRouter>
          <Index />
        </BrowserRouter>
      </I18nextProvider>
      <ToastContainer />
      </div>
  );
}

export default App;
