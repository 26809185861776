import React, { useEffect, useState } from "react";
import Section from "../../Components/Section/Section";
import {
  Button,
  Col,
  Container,
  Image,
  Row,
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  Modal,
  Card,
  Accordion,
  Spinner,
  Table,
} from "react-bootstrap";
import Heading from "../../Components/Heading/Heading";
import { getStyles } from "./OrderSuccessFail.style";
import { FaArrowRight, FaRegClock } from "react-icons/fa";
import ShopIcon from "../../assets/images/default-shop.png";
import OfferIcon from "../../assets/images/percent.png";
import ProductImage from "../../assets/images/aata.png";
import BillingIcon from "../../assets/images/billingInfo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Past_Orders, Payment_Status, View_Cart, View_Past_Orders } from "../../Api";
import { base_url } from "../../Api/ApiConst";
import TopPickForYou from "../../Components/TopPickForYou/TopPickForYou";
import BargainRecommendation from "../../Components/BargainRecommendation/BargainRecommendation";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/Store";
import { CLEAR_USER_ADDRESS, INITIAL_TAB } from "../../Redux/User";
import { CLEAR_CART } from "../../Redux/Cart";
import { toast } from "react-toastify";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { useTranslation } from "react-i18next";
import noProduct from "../../assets/images/no-product.png";

interface Product {
  PRDPrice: number;
  PRDWeight: number;
  unit_measure: string;
  current_price?: {
    discount_type?: 'amount' | 'percent';
    discount_val?: number;
  };
  // Add other product properties as needed
}

interface ProductItem {
  product: Product;
  quantity: number;
  status: string;
  note?: string; // for cancellation reason
}

const OrderSuccessFail: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = getStyles(isMobile);
  const breadcrumbs: string[] = ["Home", "Thank You"];
  const languageId: any = useSelector((state: RootState) => state.lan);

  const [selectedMethod, setSelectedMethod] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [products, setProducts] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [amount, setAmount] = useState<any>("");
  const [uuid, setUuid] = useState<any>("");
  const dispatch = useDispatch();
  const [orderId, setOrderId] = useState<string | null>(null); // Store order ID here

  const userAddress: any = useSelector(
    (state: RootState) => state.User.UserAddress
  );
  const PickUp: any = useSelector(
    (state: RootState) => state.Cart.PickupAddress
  );

  //   const [products, setProducts] = useState<any>("");
  //   const [amount, setAmount] = useState<any>("");
  const navigate = useNavigate();

  const handleMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedMethod(event.target.value);
  };

  const { first_name, last_name, address_str, city, pincode, mobile } =
    userAddress;

  const handleClear = () => {
    dispatch(CLEAR_CART());
    localStorage.removeItem(STORAGE_CONSTANTS.UUID);
    localStorage.removeItem(STORAGE_CONSTANTS.ORDER_ID);
  };

  const handleHome = () => {
    handleClear();
    dispatch(CLEAR_USER_ADDRESS());
    navigate("/");
  };

  const handleTrack = () => {
    handleClear();
    dispatch(CLEAR_USER_ADDRESS());
    navigate("/profile");
    dispatch(INITIAL_TAB("Order"));
    // navigate("/");
  };

  const fetchOrders = async () => {
    try {
      setLoading(true);
      const response = await Past_Orders(currentPage);
      if (response && response.status === 200) {
        // console.log(response.data.store_list.language_data[0].store_name);
        console.log(response.data.user_order_list[0], "orders---------->>");
        // setProducts(response.data.user_order_list[0].order_details);
        setAmount(response.data.user_order_list[0]);      
        const order_id = response.data.user_order_list[0].id; // Retrieve order ID
        setOrderId(order_id); // Store it in state to use later
        

        handleClear();
        // setStores(response.data.user_order_list);
      } else {
        console.log("Error fetching past orders");
      }
    } catch (error) {
      console.log("Error fetching past orders", error);
    } finally {
      setLoading(false);
    }
  };
console.log("order id",orderId);
const [pastOrderData, setPastOrderData] = useState<any>(null); // State to store past order data
const [product, setProduct] = useState<any>({});
const [vendorValue, setVendorValue] = useState<any>([]);
const [userToken, setUserToken] = useState<string | null>("");
useEffect(() => {
  setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
  window.scrollTo(0, 0);
}, []);

useEffect(() => {
  if (userToken) {
    fetchOrdersSummary(); // Fetch orders on userToken change
  }
}, [userToken,orderId]);
const fetchOrdersSummary = async () => {
  console.log("in fetchOrdersSummary",orderId);
  try {
    setLoading(true);
    const response = await View_Past_Orders(orderId);
    if (response && response.status === 200) {
      // setPastOrderData(response.data.cart_details.order); // Set the past order data
      setProducts(response.data.cart_details.order.order_list);
      setProduct(response.data.cart_details.order.order_details);
      setVendorValue(response.data.cart_details.order.store_order_calculation)
    } else {
      console.log("Error fetching Cart details");
    }
  } catch (error: any) {
    console.log("Error fetching Cart details", error);
  } finally {
    setLoading(false);
  }
};

  const PaymentStatus = async () => {
    if (uuid) {
      try {
        const response = await Payment_Status(uuid);
        if (response && response.status === 200) {
          console.log(response.data, "status--------------->");

          if (response.data.payment_status === true) {
            // console.log("DDDDDDDDDDDDDDDDDDDDDDD")
            toast.success("Order Placed Successfully");
            dispatch(CLEAR_CART());
            localStorage.removeItem("uuid");
            localStorage.removeItem(STORAGE_CONSTANTS.ORDER_ID);
          }
        } else {
          console.log("Error fetching Payment status");
        }
      } catch (error: any) {
        console.log("Error fetching Payment status", error);
      }
    } else {
      console.log("submit order");
    }
  };

  useEffect(() => {
    if (uuid) {
      PaymentStatus();
    }
  }, [uuid]);

  useEffect(() => {
    fetchOrders();
    // console.log(window.location.href+"kkkkkkkkkkkk")
    setUuid(localStorage.getItem(STORAGE_CONSTANTS.UUID));
    window.scrollTo(0, 0);
    // toast.success("Order SUCCESSSSSSSSSSSSSS RESPAGE Successfully")
  }, []);

  // const renderPickupAddress = (PickUpPaams: any) => {
  //   const findStore = products.find(
  //     (ele) => ele?.product?.product_vendor_store?.id == PickUpPaams?.id
  //   );

  //   // console.log("findStore----------------------->", findStore);

  //   return (
  //     !loading &&
  //     products.length !== 0 && (
  //       <div style={styles.selectedAddressWrap} className="py-2 mt-2">
  //         <Row>
  //           <Col sm={9}>
  //             <div style={styles.selectedAddressText}>
  //               <div style={styles.selectedAddressTitle}>
  //                 {findStore?.product?.product_vendor_store?.store_name}
  //               </div>
  //               {findStore?.product?.product_vendor_store?.address}{" "}
  //               {findStore?.product?.product_vendor_store?.city} <br />
  //               {findStore?.product?.product_vendor_store?.pincode}
  //             </div>
  //             {/* <div style={styles.day}>
  //               Pick up Date:
  //               <span style={styles.deliveryDate}> 12/01/2024</span>
  //             </div> */}
  //           </Col>
  //           <Col sm={3} className="hide-xs">
  //             <div style={styles.shopImageWrap}>
  //               <Image
  //                 src={
  //                   findStore?.product?.product_vendor_store?.logo
  //                     ? `${base_url}${findStore?.product?.product_vendor_store?.logo}`
  //                     : ShopIcon
  //                 }
  //                 style={styles.shopImage}
  //               />
  //             </div>
  //           </Col>
  //         </Row>
  //       </div>
  //     )
  //   );
  // };

  const getStoreWithPickUpSelected = () => {
    // console.log(PickUp, "uuuuuuid-------->");
    const storeWithPickUpSelected = PickUp.filter(
      (ele: any) => ele?.selectedOption == "Pick Up"
    );
    return storeWithPickUpSelected.length > 0;
  };

  return (
    <>
      <Section className="viewCart">
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs">
                {breadcrumbs.map((crumb, index) => (
                  <React.Fragment key={index}>
                    <span
                      style={{
                        color: index === 0 ? "var(--primary-color)" : "#BEB8B8",
                        fontFamily: "var(--primary-font-medium)",
                        fontSize: 16,
                      }}
                    >
                      {crumb}
                    </span>
                    {index !== breadcrumbs.length - 1 && (
                      <FaArrowRight
                        className="mx-2"
                        style={{ color: "#BEB8B8" }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Col>
          </Row>
          <Row className="py-4">
            <Heading text={t("cart.thankYou")} alignment="left" />
          </Row>
        </Container>
        <div style={{...styles.whiteWrap,paddingBottom:0}}>
          <Container className="below">
            <Row style={styles.cartFirst}>
              <Col sm={8} style={{...styles.cartLeftCol,borderRight:0}}>
                <div style={styles.cartTitle}>
                  <p className="mb-1"> {t("cart.confirmation")} </p>
                </div>

                {/* <div style={styles.selectedAddressWrap} className="py-4">
                  <h3 style={styles.cartTitle}>{t("cart.shippingTo")}:</h3>
                  <div style={styles.selectedAddressText}>
                    <div style={styles.selectedAddressTitle}>
                      {" "}
                      {first_name && last_name && `${first_name} ${last_name}`}
                    </div>
                    {address_str}
                    <br></br>
                    {`${city},${pincode}`}
                    <br></br> {mobile}
                  </div>
                </div> */}
                {/* {!loading && getStoreWithPickUpSelected() && (
                  <>
                    <div className="mt-4">
                      <h3 style={styles.cartTitle}>{t("cart.pickupFrom")}</h3>
                    </div>
                    {PickUp.map((ele: any) => {
                      if (ele?.selectedOption == "Pick Up") {
                        return renderPickupAddress(ele);
                      }
                    })}
                  </>
                )} 
                */}
              </Col>

           {/*       <Col sm={4} style={styles.cartRightCol}>
            
             {loading && (
                  <Row>
                    <Col className="d-flex align-items-center justify-content-center">
                      <Spinner animation="grow" variant="primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </Col>
                  </Row>
                )}
                {!loading && products.length !== 0 && (
                  <div className="success-order-summary">
                    <h3 style={styles.cartTitle}>{t("cart.orderSummary")}</h3>
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <span style={styles.value500}>
                        {t("cart.subTotal")} ({products.length}{" "}
                        {products.length > 1 ? "Items" : "Item"})
                      </span>
                      <span style={styles.value600}>₹{amount?.sub_total}</span>
                    </div>
                    {amount?.discount > 0 && (
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <span style={styles.value500}>
                          {t("cart.itemSaving")}
                        </span>
                        <span style={styles.value600}>
                          ₹{Math.floor(amount?.discount)}
                        </span>
                      </div>
                    )}
                    <hr />
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <span style={styles.value600}>
                        {t("cart.orderTotal")}
                      </span>
                      <span style={styles.value600}>₹{amount?.amount}</span>
                    </div>
                    <div style={styles.cartItem} className="success-order-item">
                      {products &&
                        products.map((ele: any) => {
                          // console.log(ele, "ele-------->");
                          return (
                            <Row
                              className="d-flex align-items-center cart-item-row "
                              style={styles.oneCart}
                            >
                              <Col sm={2} className="cartImageCol">
                                <div style={styles.productImageWrap}>
                                  <Image
                                    src={
                                      `${base_url}/${ele?.product?.product_image}` ||
                                      ProductImage
                                    }
                                    style={styles.productImage}
                                  />
                                </div>
                              </Col>
                              <Col sm={5} className="cartProductInfoCol">
                                <div style={styles.productNameWt}>
                                  <h3
                                    style={styles.productName}
                                    className="productName"
                                  >
                                    {ele?.product?.product_name}
                                  </h3>
                                  <h2 style={styles.productWt}>
                                    {ele?.product?.PRDWeight} kg
                                  </h2>
                                </div>
                              </Col>
                              <Col sm={2} className="cartQuantityCol">
                                <div
                                  className="add-to-cart"
                                  style={{ borderRadius: 10, padding: 5 }}
                                >
                                  <h2 style={styles.cartQty}>
                                    {" "}
                                    {t("userProfile.qty")} : {ele?.quantity}
                                  </h2>
                                </div>
                              </Col>
                              <Col sm={3} className="cartPriceCol">
                                <div style={styles.productPrice}>
                                  ₹{ele?.price}
                                </div>
                              </Col>
                            </Row>
                          );
                        })}
                    </div>
                  </div>
                )}
              </Col> */}
            </Row>

           
          </Container>
        </div>
      </Section>
      <Section className="view-order" style={{ marginTop: -30 }}>
        <div style={{...styles.whiteWrap,paddingTop:0}}>
          <Container>           
            <div style={styles.orderTableWrap}>
              {loading && (
                <Row>
                  <Col className="d-flex align-items-center justify-content-center">
                    <Spinner animation="grow" variant="primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </Col>
                </Row>
              )}
              {!loading && Object.keys(products).length === 0 ? (
                <Row>
                <Col className="d-flex align-items-center justify-content-center">
                  <Spinner animation="grow" variant="primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </Col>
              </Row>
              ) : (
                <>
                  {Object.keys(products).map((item, index) => (
                    <React.Fragment key={index}></React.Fragment>
                  ))}
                  <Table
                    style={styles.orderTable}
                    className="productTable mb-0"
                  >
                    {!loading && Object.keys(products).length !== 0 && (
                      <thead>
                        <tr>
                         
                          <th></th>
                          <th></th>
                          <th></th>
                                                   <th></th>
                          <th style={{ ...styles.th, textAlign: "right" }}>
                            Total
                          </th>


                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {Object.keys(products).map((item, index) => {
                        const productItems: ProductItem[] = products[item]; // Type assertion for products[item]
                        const storeId = item; // Assuming 'item' is the store ID (e.g., '221', '378', etc.)
                        const storeDetails = vendorValue[storeId]; // vendorValue contains store_order_calculation

                        

                        const discount = productItems.reduce((totalDiscount: number, productItem: ProductItem) => {
                          const discountValue = productItem.product.current_price?.discount_type === 'amount'
                            ? productItem.product.current_price.discount_val! * productItem.quantity
                            : productItem.product.current_price?.discount_type === 'percent'
                              ? (productItem.product.PRDPrice * productItem.quantity * (productItem.product.current_price.discount_val! / 100))
                              : 0;
                          return totalDiscount + discountValue;
                        }, 0);

                        return (
                          <React.Fragment key={index}>
                            <tr style={styles.cartItem}>
                              <td colSpan={6} style={{ padding: 0 }}>
                                <div
                                  className="d-flex align-items-center justify-content-between my-1"
                                  style={styles.shopWrap}
                                >
                                  <div className="d-flex align-items-center justify-content-between">
                                    <Image
                                      src={
                                        products[item][0]?.product
                                          ?.product_vendor_store?.logo
                                          ? `${base_url}/${products[item][0]?.product?.product_vendor_store?.logo}`
                                          : ShopIcon
                                      }
                                      className="hide-xs"
                                      style={{ width: 44 }}
                                    />

                                    <div className="mt-2 mx-3">
                                      <p
                                        className="mb-0"
                                        style={styles.cartTitle}
                                      >
                                        {products[item][0]?.product
                                          ?.product_vendor_store?.language_data
                                          .length === 0
                                          ? products[item][0]?.product
                                            ?.product_vendor_store?.store_name
                                          : languageId === "ne"
                                            ? products[item][0]?.product
                                              ?.product_vendor_store
                                              ?.language_data[0]?.store_name
                                            : products[item][0]?.product
                                              ?.product_vendor_store?.store_name}
                                      </p>
                                      <div
                                        style={{ display: "flex", gap: "0.2rem" }}
                                      >
                                        <p style={styles.orderInfoo}>
                                          {t("viewOrder.storeAddress")}
                                        </p>{" "}
                                        <div
                                          style={{
                                            display: "flex",
                                            gap: "0.2rem",
                                          }}
                                        >
                                          <p style={styles.orderInfoo}>
                                            {
                                              products[item][0]?.product
                                                ?.product_vendor_store?.address
                                            }
                                          </p>
                                          ,
                                          <p style={styles.orderInfoo}>
                                            {
                                              products[item][0]?.product
                                                ?.product_vendor_store?.city
                                            }
                                          </p>
                                          ,
                                          <p style={styles.orderInfoo}>
                                            {
                                              products[item][0]?.product
                                                ?.product_vendor_store?.pincode
                                            }
                                          </p>
                                        </div>
                                      </div>
                                      
                                    </div>
                                 
                                  </div>
                                  <div className="method">
  {products[item][0]?.delivery_method === "DELIVERY"
    ? "Delivery"
    : products[item][0]?.delivery_method === "PICKED_UP"
    ? "Pick Up"
    : ""}
</div>
                                </div>
                              </td>
                            </tr>
                            {products[item].map(
                              (productItem: any, subIndex: any) => (
                                <tr key={subIndex} style={{ paddingTop: 30 }}>
                                
                                  <td>
                                    <div style={styles.orderImageWrap}>
                                      <Image
                                        src={
                                          base_url +
                                          productItem?.product?.product_image
                                        }
                                        style={styles.orderImage}
                                      />
                                    </div>
                                  </td>
                                  <td style={styles.orderInfo}>
                                    <p
                                      className="mb-0"
                                      style={{
                                        fontFamily: "var(--primary-font-medium)",
                                      }}
                                    >
                                      {productItem?.product?.language_data
                                        .length === 0
                                        ? productItem?.product?.product_name
                                        : languageId === "ne"
                                          ? productItem?.product?.language_data[0]
                                            ?.product_name
                                          : productItem?.product?.product_name}
                                    </p>
                                    <p
                                      className="mb-0"
                                      style={{
                                        fontFamily: "var(--primary-font-regular)",
                                        fontSize: 16,
                                      }}
                                    >
                                      {productItem.product.PRDWeight}{" "}
                                      {productItem.product.unit_measure} {" "}<br></br>

                                      {(productItem.product.current_price?.discount_type === 'amount' || productItem.product.current_price?.discount_type === 'percent') ? (
                                        <>           {productItem.price}
                                        </>) : <>{productItem.product.PRDPrice}</>}
                                      &#10005; {" "}
                                      {productItem.quantity} (Qty)<br></br>

                                    </p>

                                  </td>

                                  <td
                                    style={{
                                      ...styles.orderInfo,
                                      textAlign: "left",
                                      fontSize: 14,
                                      fontWeight: 500
                                    }}
                                  >
                                    {productItem.product.current_price?.discount_type === 'amount' ? (
                                      <span style={{ fontWeight: 600 }}>
                                        Product Specific Promo Code - ₹{productItem.product.current_price.discount_val}  OFF
                                        <br />
                                      </span>
                                    ) : productItem.product.current_price?.discount_type === 'percent' ? (
                                      <span style={{ fontWeight: 600 }}>
                                        Product Specific Promo Code - {productItem.product.current_price.discount_val}% OFF
                                        <br />
                                      </span>
                                    ) : null}


                                  </td>
                                 <td></td>
                                
                                  <td
                                    style={{
                                      ...styles.orderInfo,
                                      textAlign: "right",
                                      fontFamily: "var(--primary-font-semibold)",
                                    }}
                                  >
                                    {(productItem.product.current_price?.discount_type === 'amount' || productItem.product.current_price?.discount_type === 'percent') ? (

                                      <span style={{
                                        textDecoration: "line-through",
                                        color: "#9f9f9f"
                                      }}>

                                        ₹{productItem.product.PRDPrice * productItem.quantity}&nbsp;&nbsp;
                                      </span>) : null}



                                    ₹{parseFloat((productItem.price * productItem.quantity).toFixed(2))}
                                    {/* {productItem.price * productItem.quantity}<br></br> */}
                                    {/* {(productItem.product.current_price?.discount_type === 'amount' || productItem.product.current_price?.discount_type === 'percent') ? (
                                      <span style={{ color: "red" }}>
                                        -{parseFloat((productItem.product.PRDPrice * productItem.quantity - productItem.price * productItem.quantity).toFixed(2))}
                                      </span>) : null} */}
                                  </td>

                                </tr>
                              )
                            )}



                            <tr style={{ borderTopWidth: 1, borderTopStyle: "solid", borderTopColor: "#d9d9d9" }}>
                              <td
                                colSpan={4}
                                style={{
                                  ...styles.orderInfo,
                                  textAlign: "right",
                                  fontSize: 14,
                                  fontWeight: 600,
                                  padding: 0,
                                  paddingBottom: 5,
                                  paddingTop: 5
                                }}
                              >
                                Vendor Specific Promo Code
                              </td>
                              <td
                                style={{
                                  ...styles.orderInfo,
                                  textAlign: "right",
                                  fontSize: 14,
                                  fontWeight: 600,
                                  padding: 0,
                                  paddingBottom: 5,
                                  paddingTop: 5
                                }}
                              >
                                ₹{Number(storeDetails.vendor_promo_code ?? 0).toFixed(2)}
                              </td>
                            </tr>
                            {discount > 0 && (
                              <tr>
                                <td colSpan={4} style={{
                                  ...styles.orderInfo,
                                  textAlign: "right",
                                  fontSize: 14,
                                  fontWeight: 600,
                                  padding: 0,
                                  paddingBottom: 5
                                }}>
                                  Discount
                                </td>
                                <td style={{
                                  ...styles.orderInfo,
                                  textAlign: "right",
                                  fontSize: 14,
                                  fontWeight: 600,
                                  padding: 0, color: "red",
                                  paddingBottom: 5
                                }}>
                                  -₹{discount.toFixed(2)}
                                </td>
                              </tr>
                            )}
                            <tr>
                              <td colSpan={4} style={{
                                ...styles.orderInfo,
                                textAlign: "right",
                                fontSize: 14,
                                fontWeight: 600,
                                padding: 0,
                                paddingBottom: 5
                              }}>
                                Shipping Charge
                              </td>
                              <td style={{
                                ...styles.orderInfo,
                                textAlign: "right",
                                fontSize: 14,
                                fontWeight: 600,
                                padding: 0,
                                paddingBottom: 5
                              }}>
                                ₹{storeDetails.shipping_charges.toFixed(2)}
                              </td>
                            </tr>

                            <tr style={{ borderBottom: 1, borderBottomStyle: "solid", borderBottomColor: "#d9d9d9", borderTopWidth: 1, borderTopStyle: "solid", borderTopColor: "#d9d9d9" }}>
                              <td colSpan={4} style={{
                                ...styles.orderInfo,
                                textAlign: "right",
                                fontSize: 14,
                                fontWeight: 900,
                                padding: 0,
                                paddingTop: 10,
                                paddingBottom: 10
                              }}>
                                SubTotal
                              </td>
                              <td style={{
                                ...styles.orderInfo,
                                textAlign: "right",
                                fontSize: 14,
                                fontWeight: 900,
                                padding: 0,
                                paddingTop: 5,
                                paddingBottom: 5
                              }}>
                                ₹{storeDetails.store_order_amount.toFixed(2)}
                              </td>
                            </tr>

                            {/* Final Total row (if needed) */}
                             <tr style={{ borderBottom: 2, borderBottomStyle: "solid", borderBottomColor: "#000" }}>
                              <td colSpan={4} style={{
                                ...styles.orderInfo,
                                textAlign: "right",
                                fontSize: 14,
                                fontWeight: 600,
                                padding: 0,
                                paddingBottom: 10
                              }}>
                                Total
                              </td>
                              <td style={{
                                ...styles.orderInfo,
                                textAlign: "right",
                                fontSize: 14,
                                fontWeight: 900,
                                padding: 0,
                                paddingBottom: 10
                              }}>
                               
                               ₹{storeDetails.total_store_order_amount.toFixed(2)}
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </Table>
                </>
              )}
            </div>
            
            {!loading && Object.keys(products).length !== 0 && (
              <>
              <hr style={{ borderTopColor: "#000", marginTop: 0 }} />
           
           <hr style={{ borderBottomColor: "#000", marginTop: 0 }} />
               <h2 style={{
              ...styles.orderInfo,
              fontSize: 20,
              fontWeight: 900
            }}>Bill Details</h2>
              <div>
                <div >
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <span style={styles.value500}>
                      {t("viewOrder.mrpTotal")}
                    </span>
                    <span style={styles.value600}>₹ {product?.sub_total}</span>
                  </div>
                  
                  <div className="d-flex align-items-center justify-content-between">
                    <span style={styles.value500}>{t("viewOrder.tax")}</span>
                    <span style={styles.value400}>0</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span style={styles.value500}>
                      {t("viewOrder.shipping")}
                    </span>
                    <span style={styles.value400}>₹ {product?.total_shipping_charges}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span style={styles.value500}>
                      Discount ({product?.coupon})
                    </span>
                    <span style={styles.value400}>
                      ₹ {product?.discount ? parseFloat(product?.discount) : 0}
                    </span>
                  </div>
                </div>
                <hr style={{ borderTopColor: "#000" }} />
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <span style={styles.value600}>
                    {/* {t("viewOrder.amount")} */}Bill Total
                  </span>
                  <span style={styles.value600}>₹ {product?.amount}</span>
                </div>
                <Row className="mt-5">
                  <Col>
                    <h2 style={styles.label}>
                     Shipping to :
                    </h2>
                    <div style={{ ...styles.value, textAlign: "left" }}>
                      {
                        <>
                  <span>{product?.shipping_data?.first_name}{" "}{product?.shipping_data?.last_name}</span><br></br>
                          <span>{product?.shipping_data?.street_address}</span>,<br></br>
                          <span>{product?.shipping_data?.City}</span><br></br>
                          <span>{product?.shipping_data?.state}</span>,<br></br>
                          <span>{product?.shipping_data?.post_code}</span><br></br>
                          <span>{product?.shipping_data?.country}</span><br></br>
                        </>
                      }
                    </div>
                  </Col>
                </Row>
              
              </div>
              </>
            )}
 <Row className="d-flex align-items-center">
                  <Col sm={6}>
                    <div className="justify-content-start d-flex mt-3">
                      <Button
                        className="btnBlue"
                        style={{ width: "100%" }}
                        onClick={handleTrack}
                      >
                        {t("cart.trackYourOrder")}
                      </Button>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="justify-content-start d-flex mt-3">
                      <Button
                        className="btnBlue"
                        style={{ width: "100%" }}
                        onClick={handleHome}
                      >
                        {t("cart.continueShopping")}
                      </Button>
                    </div>
                  </Col>
                </Row>

<Row className="mt-5">
              <TopPickForYou />
            </Row>
            <Row className="mt-5">
              <BargainRecommendation />
            </Row>
          </Container>
        </div>
      </Section>
    </>
  );
};

export default OrderSuccessFail;
