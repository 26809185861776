import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button, Image, Spinner } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Section from "../../Components/Section/Section";
import { getStyles } from "./SearchList.style";
import { base_url } from "../../Api/ApiConst";
import ShopImage from "../../assets/images/default-shop.png";
import Heart from "../../assets/images/Heart.png";
import { FaStar } from "react-icons/fa";
import noProduct from "../../assets/images/no-product.png";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/Store";
import bookmark from "../../assets/images/supply.png";
import shop1 from "../../assets/images/shop2.png";
import locationIcon from "../../assets/images/location.png";
import Dot from "../../assets/images/dot.png";
import {
  Add_To_Cart,
  Add_To_Wishlist,
  Remove_From_Cart,
  Search_Products,
  Search_Stores,
  Update_Cart,
  View_Cart,
} from "../../Api";
import { toast } from "react-toastify";
import { SET_SELECTED_PRODUCT } from "../../Redux/productId";
import { useNavigate } from "react-router-dom";
import { VIEW_CART } from "../../Redux/Cart";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartOutline } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AddToWishList,
  handleRemoveFromWishlist,
} from "../../Utils/wishListUtils";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import {
  CLEAR_SEARCH_PRODUCT,
  CLEAR_SEARCH_STORE,
  Search_Text,
  Searched_Store,
  Searched_Type,
} from "../../Redux/Search";
import { useTranslation } from "react-i18next";
import lottieNoData from "../../assets/lotties/noData.json";
import { getQuantity } from "../../Utils/cartUtils";
import NoDataFound from "../../Components/NoDataFound/NoDataFound";
import { SET_SELECTED_STORE_ID } from "../../Redux/storeSlice";
import { SET_SELECTED_STORE_SUBCATEGORY } from "../../Redux/User";

const SearchList: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const mainName = useSelector(
    (state: RootState) => state.mainCategory.selectedMainCategory
  );

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  const styles = getStyles(isMobile);
  const languageId: any = useSelector((state: RootState) => state.lan);
  const products: any = useSelector(
    (state: RootState) => state.Search.SearchText
  );
  const stores: any = useSelector(
    (state: RootState) => state.Search.SearchedStore
  );
  const [search, setSearch] = useState<any>("");
  const [userToken, setUserToken] = useState<string | null>("");
  const [currency, setCurrency] = useState("NPR");
  const [selectedTab, setSelectedTab] = useState<any>("Products");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const wishlist: any = useSelector(
    (state: RootState) => state.User.productIdInWishList
  );
  const userLatitude = useSelector(
    (state: RootState) => state.Location.selected_latitude
  );
  const userLongitude = useSelector(
    (state: RootState) => state.Location.selected_longitude
  );
  const globalSearch = useSelector(
    (state: RootState) => state.GlobalSearch.global_search
  );
  const [state, setState] = useState<boolean>();
  const outOfstock = useSelector((state: RootState) => state.offer.OutOfStock);
  const cartData = useSelector((state: RootState) => state.Cart.View_Cart);
  const userPincode = useSelector((state: RootState) => state.Location.pincode);

  useEffect(() => {
    setSearch(localStorage.getItem(STORAGE_CONSTANTS.SEARCHED_TEXT));
  }, [products, stores]);

  useEffect(() => {
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    dispatch(Searched_Type("Products"));
  }, []);

  useEffect(() => {
    if (selectedTab === "stores") {
      if (globalSearch === "") {
        navigate("/");
      } else {
        fetchStores();
      }
    } else if (selectedTab === "Products") {
      if (globalSearch === "") {
        navigate("/");
      } else {
        fetchProducts();
      }
    }
  }, [selectedTab, globalSearch]);


  const fetchStores = async () => {
    try {
      const response = await Search_Stores(
        false,
        globalSearch,
        userLatitude,
        userLongitude,
        userPincode
      );
      navigate("/search-list");
      if (response && response.status === 200) {
        localStorage.setItem(STORAGE_CONSTANTS.SEARCHED_TEXT, globalSearch);
        dispatch(Searched_Store(response.data));
        dispatch(CLEAR_SEARCH_PRODUCT());
      } else {
        console.log("Error fetching Products");
        dispatch(CLEAR_SEARCH_PRODUCT());
      }
    } catch (error: any) {
      localStorage.setItem(STORAGE_CONSTANTS.SEARCHED_TEXT, globalSearch);
      dispatch(Searched_Store(""));
      console.log("Error fetching Products", error);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await Search_Products(
        false,
        globalSearch,
        userLatitude,
        userLongitude,
        userPincode
      );
      navigate("/search-list");
      if (response && response.status === 200) {
        dispatch(Search_Text(response.data));
        dispatch(CLEAR_SEARCH_STORE());
      } else {
        console.log("Error fetching Products");
        dispatch(CLEAR_SEARCH_STORE());
      }
    } catch (error: any) {
      dispatch(Search_Text(""));
      console.log("Error fetching Searched Products", error.response.data);
    }
  };

  const manageTextLength = (text: string): string => {
    if (text.length > 40) {
      let newText = text.slice(0, 30) + "...";
      return newText;
    } else {
      return text;
    }
  };

  const handleCart = (
    id: any,
    storeId: any,
    store_name: any,
    language_data: any,
    store_logo: any,
    store_rating: any,
    minimum_qty: any,
    product_subcategory: any
  ) => {
    const addToCart = async () => {
      try {
        const response = await Add_To_Cart(id, currency, minimum_qty);
        if (response && response.status === 200) {
          console.log(response.data, "jhhhhhhhhhhhhhhh");

          localStorage.setItem(STORAGE_CONSTANTS.SHOP_ID, storeId);
          dispatch(SET_SELECTED_STORE_SUBCATEGORY(product_subcategory));
          toast.success(response["data"]["message"]);
          localStorage.setItem(
            STORAGE_CONSTANTS.ORDER_ID,
            response?.data?.order_id
          );
          const state = {
            store_name: store_name,
            language_data: language_data,
            store_logo: store_logo,
            store_rating: store_rating,
            storeId: storeId,
          };
          navigate("/store", { state: state });
          const Cartresponse = await View_Cart();
          if (Cartresponse && Cartresponse.status === 200) {
            console.log(Cartresponse.data, "kkkkkkkkkkkk");
            dispatch(VIEW_CART(Cartresponse.data));
            // console.log(Cartresponse.data,"99999999999999")
          }
        } else {
          toast.error(response["data"]["message"]);
        }
      } catch (error: any) {
        console.log("Error occurred:", error);
      }
    };
    addToCart();
  };

  // const handleWishlist = (id: any) => {
  //   const AddWishList = async () => {
  //     try {
  //       const response = await Add_To_Wishlist(userToken, id);
  //       if (response && response.status === 200) {
  //         //   console.log(response.data, "uuuuuuuuuuu")
  //         toast.success(response["data"]["message"]);
  //       } else {
  //         toast.error(response["data"]["message"]);
  //       }
  //     } catch (error: any) {
  //       console.log("Error occurred:", error.response.data.error_message);
  //       toast.error(error.response.data.error_message);
  //     }
  //   };
  //   AddWishList();
  // };

  const handleClick = (id: any) => {
    dispatch(SET_SELECTED_PRODUCT(id));
    navigate("/product-details");
  };

  const handleStore = (item: any) => {
    localStorage.setItem(STORAGE_CONSTANTS.SHOP_ID, item?.id);
    dispatch(SET_SELECTED_STORE_SUBCATEGORY({}));
    const state = {
      store_name: item?.store_name,
      store_type: item?.store_type,
      distance_in_km: item?.distance_in_km,
      language_data: item?.language_data,
      store_rating: item?.store_rating,
      store_logo: item?.logo,
      storeId: item?.id
    };
    navigate('/store', { state: state });
  };

  const handleTab = (TabName: any) => {
    console.log(TabName,"tabname=========>")
    setSelectedTab(TabName);
    dispatch(Searched_Type(TabName));
  };

  const manageBrandTextLength = (text: string): string => {
    if (text.length > 30) {
      let newText = text.slice(0, 15) + "...";
      return newText;
    } else {
      return text;
    }
  };
  console.log(selectedTab, "searchtext=============>");
  return (
    <>
      <Section style={styles.bg} className="search">
        <Container>
          <Row>
            {selectedTab === "Products" && (
              <Col>
                <h3 style={styles.searchTitle}>
                  {products.length > 0 ? products.length : 0} Results for{" "}
                  {globalSearch}
                </h3>
              </Col>
            )}
            {selectedTab === "stores" && (
              <Col>
                <h3 style={styles.searchTitle}>
                  {stores.length > 0 ? stores.length : 0} Results for{" "}
                  {globalSearch}
                </h3>
              </Col>
            )}
          </Row>
        </Container>
      </Section>
      <Section className="searchTab">
        <Container>
          <Tabs
            defaultActiveKey="Products"
            id="search-tab"
            className="mb-3"
            onSelect={(k) => handleTab(k)}
          >
            <Tab eventKey="Products" title="Products">
              {/* <div className=" py-5 row"> */}
              {/* <h3 style={styles.head}>Products for</h3> */}
              {products.length === 0 ? (
                // <div
                //   style={{
                //     display: "flex",
                //     justifyContent: "center",
                //     alignItems: "center",
                //     width: "100%",
                //   }}
                // >
                //   <h3 style={styles.noProduct}>
                //     <Image
                //       src={noProduct}
                //       className="mx-2"
                //       style={{ width: 32, height: 32 }}
                //     />
                //     {t("commonHeader.noProductsFound")}
                //   </h3>
                // </div>
                <NoDataFound title={t("commonHeader.noProductsFound")} />
              ) : (
                <Row className="py-5 global-products">
                  {/* {products &&
                    products.map((product: any) => { */}
                  {Array.isArray(products) && products.length > 0 ? (
                    products.map((product: any) => {
                      return (
                        <Col className="col-sm-3">
                          <div style={styles.product} className="product-card">
                            <div
                              style={styles.imageWrap}
                              key={product.id}
                              className="product-image-wrap"
                            >
                              {product?.current_price &&
                                product?.current_price.discount_type && (
                                  <div style={styles.offerBadgeWrap}>
                                    <div style={styles.arrowRight}></div>
                                    <p style={styles.text} className="mb-0">
                                      {product.current_price.discount_type ===
                                      "amount"
                                        ? `${product.current_price.discount_val} Rs`
                                        : `${product.current_price.discount_val} %`}
                                      <br /> OFF
                                    </p>
                                  </div>
                                )}

                              <Image
                                className="product-image"
                                src={`${base_url}/${product.product_image}`}
                                style={styles.productImage}
                                onClick={() => handleClick(product.id)}
                              />
                              <div style={styles.heartImageWrap}>
                                <FontAwesomeIcon
                                  icon={
                                    wishlist.includes(product?.id)
                                      ? faHeart
                                      : faHeartOutline
                                  }
                                  onClick={() => {
                                    {
                                      userToken
                                        ? wishlist.includes(product?.id)
                                          ? handleRemoveFromWishlist(
                                              product?.id
                                            )
                                          : AddToWishList(product?.id)
                                        : toast.error(
                                            "Login to add products to wishlist"
                                          );
                                    }
                                  }}
                                />
                              </div>
                            </div>

                            <div style={styles.productContent}>
                              <div className="d-flex align-items-center mb-2">
                                <Image
                                  src={
                                    product?.product_vendor_store?.logo
                                      ? `${base_url}/${product?.product_vendor_store?.logo}`
                                      : ShopImage
                                  }
                                  style={styles.shopIcon}
                                />
                                <h2
                                  style={styles.productShopName}
                                  className="mb-0 mx-2"
                                >
                                  {product.product_vendor_store.language_data
                                    .length === 0
                                    ? product.product_vendor_store.store_name
                                    : languageId === "ne"
                                    ? product.product_vendor_store
                                        .language_data[0].store_name
                                    : product.product_vendor_store.store_name ||
                                      "Unknown Store"}
                                </h2>
                              </div>
                              <h2
                                style={styles.productName}
                                className="product-name"
                                onClick={() => handleClick(product.id)}
                              >
                                {product.language_data &&
                                product.language_data.length > 0
                                  ? languageId === "ne"
                                    ? product.language_data[0].product_name
                                    : product.product_name || "Unknown Product"
                                  : product.product_name || "Unknown Product"}
                              </h2>
                              <div className="d-flex align-items-center justify-content-between wt-brand">
                                <h3 style={styles.quantity}>
                                  {product.PRDWeight}kg
                                </h3>
                                <div>
                                  {product?.brand && (
                                    <h3 className="brandName">
                                      {manageBrandTextLength(product.brand)}
                                    </h3>
                                  )}
                                </div>
                              </div>

                              <div style={styles.priceWrap}>
                                {product.current_price ? (
                                  <span
                                    style={styles.offerPrice}
                                    className="offerPrice"
                                  >
                                    ₹
                                    {parseFloat(
                                      product?.current_price?.current_price
                                    ).toFixed(2)}
                                  </span>
                                ) : (
                                  <span
                                    style={styles.offerPrice}
                                    className="price"
                                  >
                                    ₹{parseFloat(product?.PRDPrice).toFixed(2)}
                                  </span>
                                )}
                                {product.current_price && (
                                  <span style={styles.price} className="price">
                                    ₹{parseFloat(product?.PRDPrice).toFixed(2)}
                                  </span>
                                )}
                              </div>

                              <div className="d-grid gap-2">
                                {outOfstock.length > 0 &&
                                outOfstock.includes(product?.id) ? (
                                  <Button
                                    style={styles.btnAdd}
                                    size="lg"
                                    disabled
                                    className="btn-outOfStock"
                                  >
                                    {t("commonHeader.outOfStock")}
                                  </Button>
                                ) : (
                                  // ) : ProductQuantity > 0 ? (
                                  //   <div className="add-to-cart">
                                  //     <button
                                  //       className="quantity-button"
                                  //       onClick={() =>
                                  //         decrementQuantity(product?.id, ProductQuantity)
                                  //       }
                                  //     >
                                  //       -
                                  //     </button>
                                  //     <span className="quantity">{ProductQuantity}</span>
                                  //     <button
                                  //       className="quantity-button add"
                                  //       onClick={() =>
                                  //         incrementQuantity(product?.id, ProductQuantity)
                                  //       }
                                  //     >
                                  //       +
                                  //     </button>
                                  //   </div>
                                  // ) : (
                                  <Button
                                    style={styles.btnAdd}
                                    size="lg"
                                    onClick={() => {
                                      const storeName =
                                        product.language_data &&
                                        product.language_data.length > 0
                                          ? product.language_data[0].store_name
                                          : undefined;
                                      handleCart(
                                        product?.id,
                                        product?.product_vendor_store?.id,
                                        product.product_vendor_store
                                          ?.store_name,
                                        storeName,
                                        product?.product_vendor_store?.logo,
                                        product?.product_vendor_store
                                          ?.store_rating,
                                        product?.minimum_qty,
                                        product?.product_subcategory
                                      );
                                    }}
                                  >
                                    {t("commonHeader.add")}
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    })
                  ) : (
                    <div>
                      <NoDataFound title={t("commonHeader.noProductsFound")} />
                    </div>
                  )}
                </Row>
              )}
              {/* </div> */}
            </Tab>
            <Tab eventKey="stores" title="Stores">
              <Row className="py-5 shopRow wrapper_nearByShop">
                {stores.length === 0 ? (
                  // <div
                  //   style={{
                  //     display: "flex",
                  //     justifyContent: "center",
                  //     alignItems: "center",
                  //     width: "100%",
                  //   }}
                  // >
                  //   <h3 style={styles.noProduct}>
                  //     <Image
                  //       src={noProduct}
                  //       className="mx-2"
                  //       style={{ width: 32, height: 32 }}
                  //     />
                  //     {t("commonHeader.noStoreFound")}
                  //   </h3>
                  // </div>
                  <div>
                    <NoDataFound title={t("commonHeader.noStoreFound")} />
                  </div>
                ) : (
                  stores &&
                  stores.map((item: any) => {
                    return (
                      <Col sm={4} style={styles.shopCol}>
                        <Row style={styles.innerRow}>
                          <div
                            className="d-flex align-items-center singleShop"
                            style={styles.singleShopWrap}
                            onClick={() => handleStore(item)}
                            key={item.id}
                          >
                            <div
                              style={styles.shopImageWrap}
                              className="sm-shopImageWrap"
                            >
                              <Image
                                src={
                                  item?.logo
                                    ? `${base_url}/${item?.logo}`
                                    : ShopImage
                                }
                                style={styles.shopImage}
                              />
                            </div>

                            <div className="px-2 shopInfo" style={styles.left}>
                              <h2
                                style={styles.shopName}
                                className="mb-2 shopName"
                              >
                                {item.language_data.length === 0
                                  ? item?.store_name
                                  : languageId === "ne"
                                  ? item?.language_data[0]?.store_name
                                  : item?.store_name}
                              </h2>
                              <div className="hide-xs">
                                <p className="d-flex" style={styles.shopRating}>
                                  <FaStar className="m-1" />
                                  {item?.store_rating}
                                </p>
                              </div>
                              <div className="d-flex align-items-center mb-3 shopLocWrap">
                                {/* <h3
                                  style={styles.shopLocation}
                                  className="mb-0 d-flex align-items-center"
                                >
                                  <Image
                                    src={locationIcon}
                                    style={styles.locationIcon}
                                    className="mx-1 hide-xx"
                                  />
                                  Location
                                </h3> */}
                                {/* <Image
                                  src={Dot}
                                  style={{ width: 5, height: 5 }}
                                  className="mx-2"
                                /> */}
                                <h3
                                  style={styles.shopDistance}
                                  className="mb-0"
                                >
                                  {item?.distance_in_km}Km
                                </h3>
                              </div>

                              <div className="d-flex align-items-center mb-1">
                                <div
                                  style={{
                                    ...styles.bookmarkBg,
                                    backgroundImage: `url(${bookmark})`,
                                  }}
                                  className="supply-method"
                                >
                                  <p
                                    className="mb-0"
                                    style={styles.supplyMethod}
                                  >
                                    {item?.store_type}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </Col>
                    );
                  })
                )}
              </Row>
            </Tab>
          </Tabs>
        </Container>
      </Section>
    </>
  );
};

export default SearchList;
