import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import {
  Container,
  Form,
  Button,
  ProgressBar,
  Image,
  Modal,
  Row,
  Col,
} from "react-bootstrap";
import Section from "../../Components/Section/Section";
import { getStyles } from "./SetStore.style";
import { FaArrowRight } from "react-icons/fa";
import storeImage from "../../assets/images/default-shop.png";
import SetStoreImage from "../../assets/images/setStore.png";
import { My_Store, Set_Store, Store_Update, categories } from "../../Api";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/Store";
import { base_url } from "../../Api/ApiConst";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import { reverseGeocode } from "../../Utils/LocationFinder";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface FormValues {
  businessName: string;
  registrationNumber: string;
  email: string;
  contactNumber: string;
  registerNumber: string;
  businessType: string; // Add the registerNumber property here
}

const SetStore: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const languageId: any = useSelector((state: RootState) => state.lan);

  const styles = getStyles(isMobile);
  const [productImage, setProductImage] = useState<any>("");
  const breadcrumbs = [
    <Link to="/" key="home">
      {t("footer.home")}
    </Link>,
    "Store",
  ];
  const [address, setAddress] = useState<any>("");
  const libraries: any = ["places"];
  const googleMapsApiKey: any = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const [storeid, setStoreid] = useState<string | null>("");
  const [businessType, setBusinessType] = useState("");
  const [storetoken, setStoretoken] = useState<string | null>("");
  const [storeImages, setStoreImages] = useState("");
  const navigate = useNavigate();
  const [storeLat, setStoreLat] = useState<any>("");
  const [storeLong, setStoreLong] = useState<any>("");
  const [suggestions, setSuggestions] = useState<any>([]);

  const [storeName, setStoreName] = useState<any | null>("");
  const [id, setId] = useState<any | null>("");
  const [autocomplete, setAutocomplete] = useState<
    google.maps.places.Autocomplete | any
  >(null);
  const [registerNumber, setRegisterNumber] = useState<string>("");
  const [mainCategory, setMainCategory] = React.useState<any>([]);
  const [contactNumber, setContactNumber] = useState<string>("");
  const [storeErrors, setStoreErrors] = useState({
    openingTime: "",
    closingTime: "",
    address:""
  });
  const [openingTime, setOpeningTime] = useState<any>("");
  const [closingTime, setClosingTime] = useState<any>("");

  useEffect(() => {
    setStoretoken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    fetchCategories();
    window.scrollTo(0, 0);
  }, []);

  const handleLoad = (autocompleteInstance: any) => {
    setAutocomplete(autocompleteInstance);
  };

  const handleSearchInputChange = (event: any) => {
    const newData = event.target.value;
    setAddress(newData);
    if (newData === "") {
      setStoreErrors((storeErrors) => ({
        ...storeErrors,
        address: `* This field is required`,
      }));
    } else {
      setStoreErrors((storeErrors) => ({ ...storeErrors, address: "" }));
    }
    const service = new window.google.maps.places.AutocompleteService();
    service.getPlacePredictions(
      { input: event.target.value },
      (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setSuggestions(predictions);
          console.log("predictions--->", predictions);
        }
      }
    );
  };

  const handlePlaceChanged = async () => {
    const details = await autocomplete.getPlace();
    console.log(details,"details2=============>")

    setAddress(details.formatted_address);

    try {
      const { lat, lng } = details.geometry.location;
      const latitude = typeof lat === "function" ? lat() : null;
      const longitude = typeof lng === "function" ? lng() : null;
      console.log(latitude, "lat----------->");

      if (details?.geometry && details?.geometry?.location) {
        handleStartReverseGeocoding(latitude, longitude, googleMapsApiKey);
      }
    } catch (error) {
      console.log("error in selectLocation--->", error);
    }
  };

  const handleStartReverseGeocoding = async (
    latitude: any,
    longitude: any,
    apiKey: any
  ) => {
    try {
      const location: any = await reverseGeocode(latitude, longitude, apiKey);
      console.log(location, "loac=======>");
      if (Object.keys(location)?.length > 0) {
        reset((formData) => ({
          ...formData, // Preserve existing form values
          city: location.cityName ?? "", // Update the city field
          // address: location.address ?? ""
        }));
      }
    } catch (error) {
      console.error("Error during reverse geocoding:", error);
      throw error;
    }
  };

  useEffect(() => {
    if (storetoken) {
      const handleAPI = async () => {
        try {
          const response = await My_Store(storetoken);
          if (response && response.status === 200) {
            console.log(response.data);
            setStoreid(response.data.my_stores[0].id);
          } else {
            console.log("Error fetching My Store details");
          }
        } catch (error) {
          console.log("Error fetching My Store details", error);
        }
      };
      handleAPI();
    }
  }, [storetoken]);

  const fetchCategories = async () => {
    try {
      const response = await categories();
      if (response && response.status === 200) {
        console.log(response.data);
        setMainCategory(response.data);
      } else {
        console.log("Error fetching categories");
      }
    } catch (error) {
      console.log("Error fetching categories", error);
    }
  };

  const StoreUpdate = async () => {
    try {
      const response = await Set_Store(storeid, storetoken);
      if (response && response.status === 200) {
        console.log(response.data[0], "respoonse==========>");
        const data = response.data[0];

        setStoreName(data?.store_name);
        setRegisterNumber(data?.business_registration_number);
        setContactNumber(data?.contact_number);
        setStoreImages(data?.logo);
        setAddress(data?.address)
        setId(data?.id);
        localStorage.setItem(
          STORAGE_CONSTANTS.CONTACT_NUMBER,
          data?.contact_number
        );

        reset({
          businessName: data?.store_name ?? "",
          businessType: data?.buisness_type ?? "",
          ownerName: data?.owner_name ?? "",
          email: data?.email ?? "",
          landMark: data?.landmark ?? "",
          city: data?.city ?? "",
          nepaliName:
            data?.language_data?.length > 0
              ? data?.language_data[0]?.store_name
              : "",
          nepaliAddress:
            data?.language_data?.length > 0
              ? data?.language_data[0]?.address
              : "",
          nepaliCity:
            data?.language_data?.length > 0 ? data?.language_data[0]?.city : "",
          contactPersonName: data?.contact_person_name ?? "",
          contactPersonNumber: data?.contact_person_number ?? "",
        });

        const openTime = new Date(
          `2024-08-14T${data?.opening_time ?? "00:00:00"}`
        );
        const closeTime = new Date(
          `2024-08-14T${data?.closing_time ?? "00:00:00"}`
        );
        setOpeningTime(openTime);
        setClosingTime(closeTime);
      } else {
        console.log("Error fetching set-store details");
      }
    } catch (error) {
      console.log("Error fetching set-store details", error);
    }
  };

  useEffect(() => {
    if (storeid !== "" && storetoken !== "") {
      StoreUpdate();
    }
  }, [storeid, storetoken]);

  const handleFileChange = (e: any) => {
    console.log("product image", JSON.stringify(e.target.files[0]));
    setProductImage(e.target.files[0]);
  };

  const schema = yup.object().shape({
    businessName: yup
      .string()
      .required(t("responseMessage.businessNameRequired"))
      .matches(/^(?!\s)[\s\S]*$/, t("responseMessage.noSpace")),
    nepaliName: yup
      .string()
      .required(t("responseMessage.businessNameRequired"))
      .matches(/^(?!\s)[\s\S]*$/, t("responseMessage.noSpace")),

    businessType: yup.string().required(t("responseMessage.selectOption")),
    ownerName: yup
      .string()
      .required(t("responseMessage.ownerNameRequired"))
      .matches(/^(?!\s)[\s\S]*$/, t("responseMessage.noSpace")),
    email: yup
      .string()
      .required(t("responseMessage.emailRequired"))
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email id"
      ),
    // address: yup.string().required(t("responseMessage.addressRequired")),
    city: yup.string().required("* City is required"),
    contactPersonName: yup.string(),
    contactPersonNumber: yup.string().required("* Enter a valid phone number").matches(/^\d{10}$/),
    nepaliCity: yup.string().required("* Nepali city is required"),
    landMark: yup.string().required("* Landmark is required"),
    nepaliAddress: yup.string().required(t("responseMessage.addressRequired")),
  });

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    // defaultValues: {
    //   productName: 'Default Product Name', // Set your default value here
    // }
  });

  const handleOpening = (time: Date | null) => {
    const newData = time;
    setOpeningTime(newData);
    if (newData === null || isNaN(newData.getTime())) {
      setStoreErrors((storeErrors) => ({
        ...storeErrors,
        openingTime: `* This field is required`,
      }));
    } else {
      setStoreErrors((storeErrors) => ({ ...storeErrors, openingTime: "" }));
    }
  };

  const handleClosing = (time: Date | null) => {
    const newData = time;
    setClosingTime(newData);
    if (newData === null || isNaN(newData.getTime())) {
      setStoreErrors((storeErrors) => ({
        ...storeErrors,
        closingTime: `* This field is required`,
      }));
    } else {
      setStoreErrors((storeErrors) => ({ ...storeErrors, closingTime: "" }));
    }
  };

  const handleSubmits = (data: any) => {
    let formIsValid = true;
    if (openingTime === null || isNaN(openingTime.getTime())) {
      setStoreErrors((storeErrors) => ({
        ...storeErrors,
        openingTime: `* This field is required`,
      }));
      formIsValid = false;
    }

    if (
      openingTime !== null &&
      openingTime.toString() === closingTime.toString()
    ) {
      setStoreErrors((storeErrors) => ({
        ...storeErrors,
        openingTime: `* Open and close timing's can not be same`,
      }));
      formIsValid = false;
    }

    if (closingTime === null || isNaN(closingTime.getTime())) {
      setStoreErrors((storeErrors) => ({
        ...storeErrors,
        closingTime: `* This field is required`,
      }));
      formIsValid = false;
    }

    if (address === "") {
      setStoreErrors((storeErrors) => ({
        ...storeErrors,
        address: `* This field is required`,
      }));
      formIsValid = false;
    }

    if (data && formIsValid) {
      const updateData = async () => {
        try {
          const response = await Store_Update(
            id,
            data,
            registerNumber,
            address,
            productImage,
            languageId,
            openingTime,
            closingTime
          );
          if (response && response.status === 200) {
            toast.success(response["data"]["message"]);
            navigate("/other-information");
          } else {
            toast.error(response["data"]["message"]);
          }
        } catch (error) {
          console.log("Error updating set-store", error);
        }
      };
      updateData();
    }
  };

  const handleBussiness = (e: any) => {
    setBusinessType(e.target.value);
  };

  return (
    <>
      <Section className="setStore" style={styles.bg}>
        <Container>
          <Row>
            <div className="breadcrumbs">
              {breadcrumbs.map((crumb, index) => (
                <React.Fragment key={index}>
                  <span
                    style={{
                      color: index === 0 ? "var(--primary-color)" : "#BEB8B8",
                      fontFamily: "var(--primary-font-medium)",
                      fontSize: 16,
                    }}
                  >
                    {crumb}
                  </span>
                  {index !== breadcrumbs.length - 1 && (
                    <FaArrowRight
                      className="mx-2"
                      style={{ color: "#BEB8B8" }}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          </Row>
          <Row style={{ position: "relative" }}>
            <Col sm={1}></Col>
            <Col sm={7}>
              <div style={styles.storeWrap}>
                <div style={styles.storeTopHeader}>
                  <h3 style={styles.welcome}>{t("login.welcomeText")}</h3>
                </div>
                <div style={styles.storeHeader}>
                  <div style={styles.storeImageWrap}>
                    <Image
                      src={
                        storeImages ? `${base_url}${storeImages}` : storeImage
                      }
                      style={styles.storeImage}
                    />
                  </div>
                  <div style={styles.storeNameWrap}>
                    <h2 style={styles.storeName}>{storeName}</h2>
                  </div>
                </div>
                <div style={styles.storeBottomHeader}>
                  <h3 style={styles.set}>
                    {t("storeRegister.updateYourStore")}
                  </h3>
                </div>
              </div>
            </Col>
            <Col sm={4} className="hide-xs">
              <Image src={SetStoreImage} style={styles.titleImage} />
            </Col>
          </Row>
        </Container>
      </Section>
      <Section className="setStoreForm" style={{ marginTop: -30 }}>
        <div style={styles.whiteWrap}>
          <Container>
            <Row>
              <Col>
                <Form onSubmit={handleSubmit(handleSubmits)}>
                  <Form.Group controlId="businessName">
                    <Form.Label>{t("storeRegister.businessName")}</Form.Label>
                    <Form.Control
                      type="text"
                      // value={businessName}
                      // onChange={(e) => setBusinessName(e.target.value)}
                      {...register("businessName")}
                      // isInvalid={!!errors.businessName}
                    />
                    {errors.businessName && (
                      <span style={styles.error}>
                        {errors.businessName.message}
                      </span>
                    )}
                  </Form.Group>
                  {/* 
                  <Form.Group controlId="nepaliName">
                    <Form.Label>{"Nepali Name"}</Form.Label>
                    <Form.Control
                      type="text"
                      // value={businessName}
                      // onChange={(e) => setBusinessName(e.target.value)}
                      {...register("nepaliName")}
                      // isInvalid={!!errors.businessName}
                    />
                    {errors.nepaliName && (
                      <span style={styles.error}>
                        {errors.nepaliName.message}
                      </span>
                    )}
                  </Form.Group> */}

                  <Form.Group controlId="registrationNumber">
                    <Form.Label>
                      {t("storeRegister.businessRegistrationNumber")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={registerNumber}
                      readOnly
                      disabled
                      // isInvalid={!!errors.registrationNumber}
                    />
                    {/* <Form.Control.Feedback type="invalid">
                      {errors.registrationNumber}
                    </Form.Control.Feedback> */}
                  </Form.Group>

                  <Form.Group controlId="industry">
                    <Form.Label>{t("storeRegister.businessType")}:</Form.Label>
                    <Controller
                      name="businessType"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Form.Control as="select" {...field}>
                          <option value="" disabled>
                            {t("storeRegister.selectBusinessType")}
                          </option>
                          {mainCategory &&
                            mainCategory.map((item: any) => (
                              <option key={item.name} value={item.name}>
                                {`${item.name} Shop`}
                              </option>
                            ))}
                        </Form.Control>
                      )}
                    />
                    {errors.businessType && (
                      <span style={{ color: "red" }}>
                        {errors.businessType.message}
                      </span>
                    )}
                  </Form.Group>

                  <Form.Group controlId="ownerName">
                    <Form.Label>{t("storeRegister.ownerName")}</Form.Label>
                    <Form.Control
                      type="text"
                      // value={businessName}
                      // onChange={(e) => setBusinessName(e.target.value)}
                      {...register("ownerName")}
                    />
                    {errors.ownerName && (
                      <span style={styles.error}>
                        {errors.ownerName.message}
                      </span>
                    )}
                  </Form.Group>

                  <Form.Group controlId="email">
                    <Form.Label>{t("storeRegister.emailId")}</Form.Label>
                    <Form.Control
                      type="email"
                      // value={businessName}
                      // onChange={(e) => setBusinessName(e.target.value)}
                      {...register("email")}
                      // isInvalid={!!errors.email}
                    />
                    {errors.email && (
                      <span style={styles.error}>{errors.email.message}</span>
                    )}
                  </Form.Group>

                  <Form.Group controlId="contactNumber">
                    <Form.Label>{t("storeRegister.contactNumber")}</Form.Label>
                    <Form.Control
                      type="tel"
                      value={contactNumber}
                      readOnly
                      disabled
                      // isInvalid={!!errors.contactNumber}
                    />
                    {/* <Form.Control.Feedback type="invalid">
                      {errors.contactNumber}
                    </Form.Control.Feedback> */}
                  </Form.Group>

                  <Form.Group controlId="contactPersonName">
                    <Form.Label>
                      {t("storeRegister.contactPersonName")}:
                    </Form.Label>
                    <Form.Control
                      type="text"
                      // name="contactPersonName"
                      // value={contactPersonName}
                      // onChange={(e) => setContactPersonName(e.target.value)}
                      {...register("contactPersonName")}
                    />
                    {/* {errorss.contactPersonName && (
                    <span style={styles.error}>{errorss.contactPersonName}</span>
                  )} */}
                  </Form.Group>

                  <Form.Group controlId="contactPersonNumber">
                    <Form.Label>
                      {t("storeRegister.contactPersonNumber")}:
                    </Form.Label>
                    <Form.Control
                      type="text"
                      // name="contactPersonNumber"
                      // value={contactPersonNumber}
                      // onChange={(e) => setContactPersonNumber(e.target.value)}
                      {...register("contactPersonNumber")}
                      maxLength={10}
                    />
                    {errors.contactPersonNumber && (
                      <span style={styles.error}>{errors.contactPersonNumber.message}</span>
                    )}
                  </Form.Group>

                  <Form.Group controlId="searchStoreAddress">
                    <Form.Label>{"Address"}</Form.Label>
                    <div className="locationAutoComplete">
                      <LoadScript
                        googleMapsApiKey={googleMapsApiKey}
                        libraries={libraries}
                      >
                        <Autocomplete
                          onLoad={handleLoad}
                          onPlaceChanged={handlePlaceChanged}
                        >
                          <div id="location-popup">
                            <Form.Control
                              id="location-search"
                              type="text"
                              placeholder="Search for places..."
                              className="btnWhite"
                              value={address}
                              onChange={handleSearchInputChange}
                            />
                            {storeErrors.address && (
                            <span style={styles.error}>
                              {storeErrors.address}
                            </span>
                          )}
                          </div>
                        </Autocomplete>
                      </LoadScript>
                    </div>
                  </Form.Group>

                  <Form.Group controlId="address">
                    <Form.Label>{"LandMark"}</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      {...register("landMark")}
                    />
                    {errors.landMark && (
                      <span style={styles.error}>{errors.landMark.message}</span>
                    )}
                  </Form.Group>

                  <Form.Group controlId="city">
                    <Form.Label>{t("storeRegister.city")}:</Form.Label>
                    <Form.Control type="text" {...register("city")} disabled />
                    {errors.city && (
                      <span style={styles.error}>{errors.city.message}</span>
                    )}
                  </Form.Group>

                 
                  <div className="row storeTime">
                    <Form.Group
                      className="col-md-6"
                      controlId="storeOpeningTime"
                    >
                      <Form.Label>
                        {t("storeRegister.storeOpeningTime")}
                      </Form.Label>
                      <DatePicker
                        selected={openingTime}
                        onChange={handleOpening}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="form-control mx-3"
                      />
                      <br />
                      {storeErrors.openingTime && (
                        <span style={styles.error}>
                          {storeErrors.openingTime}
                        </span>
                      )}
                    </Form.Group>

                    <Form.Group
                      className="col-md-6"
                      controlId="storeClosingTime"
                    >
                      <Form.Label>
                        {t("storeRegister.storeClosingTime")}
                      </Form.Label>
                      <DatePicker
                        selected={closingTime}
                        onChange={handleClosing}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="form-control  mx-3"
                      />
                      <br />
                      {storeErrors.closingTime && (
                        <span style={styles.error}>
                          {storeErrors.closingTime}
                        </span>
                      )}
                    </Form.Group>
                  </div>
                  <p style={styles.heading}>{t("product.otherLanguage")}</p>
                  <Form.Group controlId="nepaliName">
                    <Form.Label>{"Nepali Name"}</Form.Label>
                    <Form.Control
                      type="text"
                      // value={businessName}
                      // onChange={(e) => setBusinessName(e.target.value)}
                      {...register("nepaliName")}
                      // isInvalid={!!errors.businessName}
                    />
                    {errors.nepaliName && (
                      <span style={styles.error}>
                        {errors.nepaliName.message}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group controlId="nepaliAddress">
                    <Form.Label>{"Nepali Address"}</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      {...register("nepaliAddress")}
                    />
                    {errors.nepaliAddress && (
                      <span style={styles.error}>
                        {errors.nepaliAddress.message}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group controlId="nepaliCity">
                    <Form.Label>{"Nepali City"}:</Form.Label>
                    <Form.Control type="text" {...register("nepaliCity")} />
                    {errors.nepaliCity && (
                      <span style={styles.error}>
                        {errors.nepaliCity.message}
                      </span>
                    )}
                  </Form.Group>
                  <Row className="align-items-center xs-row-wrap">
                    <Col sm={1}>
                      <div style={{ ...styles.storeImageWrap, margin: "auto" }}>
                        <Image
                          src={
                            storeImages
                              ? `${base_url}${storeImages} `
                              : storeImage
                          }
                          style={styles.storeImage}
                        />
                      </div>
                    </Col>
                    <Col sm={11}>
                      <Form.Group controlId="logo">
                        <Form.Label>{t("storeRegister.logo")}</Form.Label>
                        <Form.Control
                          type="file"
                          onChange={(e) => handleFileChange(e)}
                        />
                        {/* <Form.Control.Feedback type="invalid">
                   {errors.productImage}
                 </Form.Control.Feedback> */}
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className="align-items-center justify-content-center d-flex">
                    <Button
                      variant="primary"
                      className="btnBlue submit"
                      // style={styles.btnBlue}
                      type="submit"
                    >
                      {t("commonFormLabels.submitNext")}
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </Section>
    </>
  );
};

export default SetStore;
