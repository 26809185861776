import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Button,
  Image,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Modal,
  Overlay,
} from "react-bootstrap";
import { getStyles } from "./StoreView.style";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Import Link and To from react-router-dom
import Section from "../../Components/Section/Section";
import { FaArrowRight, FaRegClock, FaSearch, FaStar } from "react-icons/fa";
import storeImage from "../../assets/images/default-shop.png";
import { Rating } from "react-simple-star-rating";
import SubcategoryWiseProduct from "../../Components/SubcategoryWiseProduct/SubcategoryWiseProduct";
import MainCategory from "../../Components/MainCategories/MainCategories";
import StoreSubcategoryWiseProduct from "../../Components/StoreSubcategoryWiseProduct/StoreSubcategoryWiseProduct";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/Store";
import FormControl from "react-bootstrap/esm/FormControl";
import { SET_STORE_VIEW_SEARCH } from "../../Redux/Search";
import { useTranslation } from "react-i18next";
import { base_url } from "../../Api/ApiConst";
import { SET_SELECTED_PRODUCT, SET_SELECTED_SUBCATEGORY_ID } from "../../Redux/productId";
import { Search_Store_Product, Search_Store_Product_Suggestions, Selected_Store_Rating, User_Last_Viewed } from "../../Api";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { parseInt } from "lodash";
import defaultAvtaar from "../../assets/images/User.png";
import { toast } from "react-toastify";
import NoDataFound from "../../Components/NoDataFound/NoDataFound";
import Popover from "react-bootstrap/esm/Popover";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import debounce from "lodash.debounce";

const StoreView: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const { t, i18n } = useTranslation();
  const [showModal, setShowModal] = React.useState<any>(false); // State to control modal visibility
  const [reviews, setReviews] = React.useState<any>([]); // State to store reviews
  const [suggestions,setSuggestions] = React.useState<any>([])
  const [currentPage, setCurrentPage] = React.useState<any>(1);
  const [isSearch, setIsSearch] = React.useState<any>("true");
  const [userToken,setUserToken] = React.useState<any>("")
  const styles = getStyles(isMobile);
  const location = useLocation();
  const state = location.state;
  const navigate = useNavigate()
  const {
    store_name,
    store_type,
    distance_in_km,
    language_data,
    store_logo,
    store_rating,
    storeId,
  } = state;
  const searchTerm: any = useSelector(
    (state: RootState) => state.Search.StoreViewSearch
  );

  const SelectedSubcategory: any = useSelector(
    (state: RootState) => state.productId.selectedSubcategory_id
  );

  const [searchText, setSearchText] = useState<any>("");
  const mainName = useSelector(
    (state: RootState) => state.mainCategory.selectedMainCategory
  );
  const languageId: any = useSelector((state: RootState) => state.lan);

  const dispatch = useDispatch();

  const breadcrumbs = [
    <Link to="/" key="home">
      {t("footer.home")}
    </Link>,
    mainName?.language_data?.length === 0
      ? mainName?.name
      : languageId === "ne"
      ? mainName?.language_data[0]?.name
      : mainName?.name,
  ];

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
    dispatch(SET_SELECTED_SUBCATEGORY_ID(""));
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    StoreRating();
  }, []);

  useEffect(() => {
    debouncedHandleSearch(searchText);
  }, [searchText]);

  useEffect(() => {
    setSearchText(searchTerm);
  }, [searchTerm]);

  const handleSearch = (name:any) => {
    dispatch(SET_STORE_VIEW_SEARCH(name ? name : searchText));
  };

  

  const debouncedHandleSearch = debounce(async (searchText: any) => {
    if (searchText) {
      try {
        const response = await Search_Store_Product_Suggestions(
          searchText,
          storeId,
          isSearch,
        );
          if (response && response.status === 200) {
            const formattedStores = response.data.product_list.map((product:any) => ({
              id: product?.id,
              img:product?.product_image,
              name: product?.product_name, // This is important, `name` is the key used in the items
            }));
            setSuggestions(formattedStores);
            dispatch(
              SET_SELECTED_SUBCATEGORY_ID(
                response?.data?.search_product_list[0]?.product_subcategory
              )
            );
        } else {
          console.log("Error fetching Store Product Details");
        }
      } catch (error) {
        console.log("Error fetching Store Product Details", error);
      } 
    } 
  }, 500)

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch("");
    }
  };

  // const NavigateToProducts = (item: any) => {
  //   dispatch(SET_SELECTED_PRODUCT(item?.id));
  //   {userToken && User_Last_Viewed(item?.id)};
  //   navigate("/product-details");
  // };


  const StoreRating = async () => {
    try {
      const response = await Selected_Store_Rating(storeId);
      if (response && response.status === 200) {
        // console.log(response.data.store_list.language_data[0].store_name);
        console.log(response.data, "buyyyy---------->");
        // setProducts(response.data.product_list);
        if (response.data.status === true) {
          setReviews(response.data.store_rating_list);
        } else {
          setReviews([]);
        }
        // setReviews(response?.data?.store_rating_list);
        // setComment(response?.data?.store_rating_list[0]?.client_comment);
      } else {
        console.log("Error fetching Past orders");
        toast.error(response?.data?.error);
      }
    } catch (error) {
      console.log("Error fetching Past orders", error);
    }
  };

  const fetchReviews = () => {
    // Simulated API call or data fetching
    setShowModal(true); // Show modal after reviews are fetched
  };


  return (
    <div className="store">
      <Section className="storeView" style={styles.bg}>
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs">
                {breadcrumbs.map((crumb, index) => (
                  <React.Fragment key={index}>
                    <span
                      style={{
                        color: index === 0 ? "var(--primary-color)" : "#BEB8B8",
                        fontFamily: "var(--primary-font-medium)",
                        fontSize: 16,
                      }}
                    >
                      {crumb}
                    </span>
                    {index !== breadcrumbs.length - 1 && (
                      <FaArrowRight
                        className="mx-2"
                        style={{ color: "#BEB8B8" }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Col>
          </Row>

          <Row>
            {/* <Col sm={2}></Col> */}
            <Col sm={12} style={{ paddingBottom: 0 }}>
              <div style={styles.storeWrap}>
                <div style={styles.storeHeader}>
                  <div style={styles.storeImageWrap}>
                    <Image
                      src={
                        store_logo ? `${base_url}/${store_logo}` : storeImage
                      }
                      style={styles.storeImage}
                    />
                  </div>
                  <div style={styles.storeNameWrap}>
                    <h2 style={styles.storeName}>
                      {language_data === undefined
                        ? store_name
                        : languageId === "ne"
                        ? language_data
                        : store_name}
                    </h2>

                    {store_rating !== undefined && (
                      // <OverlayTrigger
                      //   placement="right"
                      //   overlay={
                      //     <Tooltip id="rating-tooltip">
                      //       Customer Reviews{" "}
                      //     </Tooltip>
                      //   }
                      // >
                        <div
                          style={styles.ratingWrap}
                          onClick={fetchReviews} // Trigger fetchReviews on click
                        >
                          <Rating
                            initialValue={store_rating}
                            allowHover={false}
                            readonly={true}
                            size={22}
                            style={{ marginBottom: "7rem" }}
                          />

                          <p
                            style={{
                              fontSize: 20,
                              fontFamily: "var(--primary-font-semibold)",
                              color: "#000",
                            }}
                            className="mb-0 mx-2"
                          >
                            {Math.round(store_rating)}
                          </p>
                        </div>
                      // </OverlayTrigger>
                    )}
                  </div>
                </div>
                {/* <h3 style={styles.location}>Location</h3> */}
                {/* <div style={styles.timeWrap}>
                  <FaRegClock
                    className="mx-2"
                    style={{ color: "#000", width: 20, height: 20 }}
                  />
                  <h3 style={styles.time}>08 min</h3>
                </div> */}
              </div>
            </Col>
          </Row>
          <Row style={styles.searchRow}>
            <ReactSearchAutocomplete
              className="mr-sm-2 search-input"
               items={suggestions}
               onSearch={(input: string) => setSearchText(input)} 
               onSelect={(item:any) => handleSearch(item?.name)}
               formatResult={(item: any) => (
                <div>
                   {item?.img && <span>
                   <img src={`${base_url}${item?.img}`} style={{width:"2rem"}}/>
                 </span>} 
                  <span>{item?.name}</span>
                </div>
              )}
               autoFocus
              />
              <Button
                variant="outline-primary search-btn"
                onClick={() => handleSearch("")}
              >
                <FaSearch />
              </Button>
          </Row>
        </Container>
      </Section>
      <StoreSubcategoryWiseProduct />
      <Modal
        centered
        size="lg"
        show={showModal}
        onHide={() => setShowModal(false)}
        className="ratingModal storeRatingModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("userProfile.storeReviews")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {reviews && reviews.length === 0 ? (
            <NoDataFound title={t("commonHeader.noReviews")} />
          ) : (
            reviews.length > 0 &&
            reviews &&
            reviews.map((review: any) => (
              <div key={review.id} className="mb-3 store-rating-wrap">
                <div className="d-flex align-items-start">
                  <Image
                    src={review.user_image ? review.user_image : defaultAvtaar}
                    roundedCircle
                    width={50}
                    height={50}
                    style={{ marginRight: 10 }}
                  />
                  <div>
                    <h5 className="mb-0">
                      {review.customer_name
                        ? review.customer_name
                        : "Anonymous User"}
                    </h5>
                    <Rating
                      initialValue={review.rate}
                      allowHover={false}
                      readonly={true}
                      size={22}
                      style={{ marginBottom: "0.5rem" }}
                    />
                    <p className="mb-0">{review.client_comment}</p>
                  </div>
                </div>
              </div>
            ))
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            {t("commonHeader.close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default StoreView;
