import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Image,
  Table,
  Spinner,
  Overlay,
  Popover,
  InputGroup,
} from "react-bootstrap";
import { getStyles } from "./Products.style";
import Section from "../../Components/Section/Section";
import {
  FaAngleRight,
  FaArrowRight,
  FaRegClock,
  FaSearch,
  FaSort,
} from "react-icons/fa";
import Search from "../../Components/Search/Search";
import shopImage from "../../assets/images/default-shop.png";
import Sort from "../../assets/images/sort.png";
import ProductImage from "../../assets/images/onion.png";
import { Link, useNavigate } from "react-router-dom";
import {
  My_Store,
  Search_Store_Product,
  Search_Store_Product_Suggestions,
  Sort_Product_List,
  Store_Product_Details,
} from "../../Api";
import { base_url } from "../../Api/ApiConst";
import noProduct from "../../assets/images/no-product.png";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import FormControl from "react-bootstrap/esm/FormControl";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/Store";
import { useTranslation } from "react-i18next";
import NoDataFound from "../../Components/NoDataFound/NoDataFound";
import { SET_SELECTED_SUBCATEGORY_ID } from "../../Redux/productId";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import debounce from "lodash.debounce";

interface Category {
  id: string;
  product_name: string;
  product_image: string;
  PRDPrice: number;
  PRDWeight: string;
  PRDISactive: boolean;
  product_subcategory: any;
  name: string;
}

const Products: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = getStyles(isMobile);
  const breadcrumbs = [
    <Link to="/store-dashboard" key="home">
      Home
    </Link>,
    "Products",
  ];

  const { t, i18n } = useTranslation();

  const sortData = [
    {
      id: 1,
      Sorttype: "rating",
      name: "Rating(Ascending)",
      Ascending: true,
    },
    {
      id: 2,
      Sorttype: "-rating",
      name: "Rating(Descending)",
      Ascending: false,
    },
    {
      id: 3,
      Sorttype: "product_name",
      name: "Product Name(Ascending)",
      Ascending: true,
    },
    {
      id: 4,
      Sorttype: "-product_name",
      name: "Product Name(Descending)",
      Ascending: false,
    },
    {
      id: 5,
      Sorttype: "PRDPrice",
      name: "PRDPrice(Ascending)",
      Ascending: true,
    },
    {
      id: 6,
      Sorttype: "-PRDPrice",
      name: "PRDPrice(Descending)",
      Ascending: false,
    },
    {
      id: 7,
      Sorttype: "brand",
      name: "Brand(Ascending)",
      Ascending: true,
    },
    {
      id: 8,
      Sorttype: "-brand",
      name: "Brand(Descending)",
      Ascending: false,
    },
    {
      id: 9,
      Sorttype: "date",
      name: "Date(Ascending)",
      Ascending: true,
    },
    {
      id: 10,
      Sorttype: "-date",
      name: "Date(Descending)",
      Ascending: false,
    },
  ];

  const [showSortPopover, setShowSortPopover] = useState(false);
  const target = useRef(null);
  const [sortBy, setSortBy] = useState<string>("");
  const [userToken, setUserToken] = useState<string | null>("");
  const [storeName, setStoreName] = useState<string | null>("");
  const [storeId, setStoreId] = useState<string | null>("");
  const [product, setProduct] = useState<any[]>([]);
  const [store, setStore] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [pages, setPages] = useState<any>({});
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<any>("");
  const [businessNumber, setBusinessNumber] = useState<string | null>("");
  const languageId: any = useSelector((state: RootState) => state.lan);
  const [isSearchResult, setIsSearchResult] = useState<boolean>(false); // Track if the current view is search results
  const [storeLogo, setStoreLogo] = useState<any>("");
  const [prodStatus, setProdStatus] = useState<any>("");
  const [suggestions, setSuggestions] = React.useState<any>([]);
  const [isSearch, setIsSearch] = React.useState<any>("true");

  const navigate = useNavigate();

  useEffect(() => {
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    setStoreName(localStorage.getItem(STORAGE_CONSTANTS.STORE_NAME));
    setStatus(localStorage.getItem(STORAGE_CONSTANTS.STORE_STATUS));
    window.scrollTo(0, 0);
  }, []);

  const handleSearch = () => {
    fetchSearchProducts("");
    if (searchText === "") {
      setIsSearchResult(false);
      setCurrentPage(1);
      return fetchProductList(prodStatus);
    }
    setIsSearchResult(true);
  };

  useEffect(() => {
    if (userToken) {
      const handleAPI = async () => {
        try {
          const response = await My_Store(userToken);
          if (response && response.status === 200) {
            console.log(response.data);
            setStoreId(response.data.my_stores[0].id);
            setStore(response.data.my_stores[0].store_name);
            setBusinessNumber(
              response.data.my_stores[0].business_registration_number
            );
            setStoreLogo(response.data.my_stores[0].logo);
          } else {
            console.log("Error fetching My Store details");
          }
        } catch (error) {
          console.log("Error fetching My Store details", error);
        }
      };
      handleAPI();
    }
  }, [userToken]);

  useEffect(() => {
    if (searchText) {
      fetchSearchProducts("");
    } else {
      fetchProductList(prodStatus);
    }
  }, [storeId, currentPage]);

  useEffect(() => {
    debouncedHandleSearch(searchText);
  }, [searchText]);

  //   const getProductList = async () => {

  //         if (searchText) {

  //           let  productList = await Search_Store_Product(storeId, searchText);
  //             setProduct(productList?.data?.store_product_list?.sort((a: any, b: any) => b.id - a.id))

  //         }
  //         else {
  //           let response = await Store_Product_Details(storeId, currentPage);
  //           setProduct(response.data.search_product_list?.sort((a: any, b: any) => b.id - a.id))
  //           setCurrentPage(response.data.count)

  //         }

  // }

  const fetchProductList = async (prodStatusFromParam: any) => {
    try {
      setLoading(true);
      const response = await Store_Product_Details(
        storeId,
        currentPage,
        prodStatusFromParam
      );
      if (response && response.status === 200) {
        console.log(response.data, "yuuuuuuuuuu");
        setPages(response.data.count);
        // setCurrentPage(response.data.count);
        setProduct(response.data.store_product_list);
      } else {
        console.log("Error fetching Store Product Details");
      }
    } catch (error) {
      console.log("Error fetching Store Product Details", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSearchProducts = async (text:any) => {
    setProdStatus("");
    try {
      setLoading(true)
      const response = await Search_Store_Product(
        searchText,
        storeId,
        currentPage,
        text
      );
      if (response && response.status === 200) {
        setProduct(response.data.search_product_list);
      } else {
        console.log("Error fetching Store Product Details");
      }
    } catch (error) {
      console.log("Error fetching Store Product Details", error);
    }finally{
      setLoading(false)
    }
  };

  const handleClick = (id: any) => {
    const state = {
      product_id: id,
    };
    navigate("/view-product", { state: state });
  };

  // const handleNext = () => {
  //   setCurrentPage(currentPage + 1);
  // };

  // const handleBack = () => {
  //   setCurrentPage(currentPage - 1);
  // };

  const handleSort = async(item: any) => {
    try {
      setLoading(true)
      const response = await Sort_Product_List(
        storeId,
        item?.Sorttype
      );
      if (response && response.status === 200) {
        setProduct(response.data.store_product_list);
      } else {
        console.log("Error fetching Store Product Details");
      }
    } catch (error) {
      console.log("Error fetching Store Product Details", error);
    }finally{
      setLoading(false)
    }
  };

  const getPRDISactiveStatus = (isActive: boolean): string => {
    return isActive ? "Active" : "Inactive";
  };
  const handleOverlayClose = () => {
    setShowSortPopover(false);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const debouncedHandleSearch = async (searchText: any) => {
    if (searchText) {
      try {
        const response = await Search_Store_Product_Suggestions(searchText, storeId,isSearch);
        if (response && response.status === 200) {
          const formattedStores = response.data.product_list.map(
            (product: any) => ({
              id: product?.id,
              img: product?.product_image,
              name: product?.product_name, // This is important, `name` is the key used in the items
            })
          );
          setSuggestions(formattedStores);
        } else {
          console.log("Error fetching Store Product Details");
        }
      } catch (error) {
        console.log("Error fetching Store Product Details", error);
      }
    }
  };

  const handleStatusFilter = (status: string) => {
    // console.log(status, "statusss");
    setCurrentPage(1);
    setProdStatus(status);
    fetchProductList(status);
  };

  return (
    <>
      <Section className="productList" style={styles.bg}>
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs">
                {breadcrumbs.map((crumb, index) => (
                  <React.Fragment key={index}>
                    <span
                      style={{
                        color: index === 0 ? "var(--primary-color)" : "#BEB8B8",
                        fontFamily: "var(--primary-font-medium)",
                        fontSize: 16,
                      }}
                    >
                      {crumb}
                    </span>
                    {index !== breadcrumbs.length - 1 && (
                      <FaArrowRight
                        className="mx-2"
                        style={{ color: "#BEB8B8" }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Col>
          </Row>
          <Row style={styles.searchRow}>
            <ReactSearchAutocomplete
              className="mr-sm-2 search-input"
              items={suggestions}
              onSearch={(input: string) => setSearchText(input)}
              onSelect={(item: any) => fetchSearchProducts(item?.name)}
              inputDebounce={500}
              formatResult={(item: any) => (
                <div>
                  {item?.img && (
                    <span>
                      <img
                        src={`${base_url}${item?.img}`}
                        style={{ width: "2rem" }}
                      />
                    </span>
                  )}
                  <span>{item?.name}</span>
                </div>
              )}
              autoFocus
            />
            <Button variant="outline-primary search-btn" onClick={handleSearch}>
              <FaSearch />
            </Button>
          </Row>
          {/* <Row style={styles.searchRow}>
            <Search onSearch={handleSearch} />
          </Row> */}
        </Container>
      </Section>
      <Section className="products" style={{ marginTop: -30 }}>
        <div style={styles.whiteWrap}>
          <Container>
            <div className="d-flex align-items-center justify-content-between flex-row add-row">
              <div className="d-flex align-items-center justify-content-start flex-row">
                <div style={styles.shopImageWrap}>
                  <Image
                    src={storeLogo ? `${base_url}${storeLogo}` : shopImage}
                    style={styles.shopImage}
                  />
                </div>
                <div style={styles.shopNameWrap}>
                  <h3 style={styles.shopName} className="mx-2">
                    {store}
                  </h3>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-start flex-row add-product-wrap" >
                <Link to="/add-product">
                  <Button style={styles.addBtn}>{t("commonHeader.add")}</Button>
                </Link>
                <div ref={target}>
                  <Button
                    style={styles.btnSort}
                    variant="outline-primary"
                    onClick={() => setShowSortPopover(!showSortPopover)}
                  >
                    <Image
                      src={Sort}
                      style={styles.btnSortIcon}
                      className="sortImage"
                    />
                  </Button>
                  <Overlay
                    show={showSortPopover}
                    target={target.current}
                    placement="bottom"
                    containerPadding={20}
                    container={document.body}
                    onHide={handleOverlayClose}
                    rootClose
                  >
                    <Popover id="popover-contained">
                      {/* <Popover.Header as="h3">Sort By</Popover.Header> */}
                      <Popover.Body>
                        <ul
                          style={{ listStyleType: "none", padding: 0 }}
                          className="sortPopover"
                        >
                          {sortData &&
                            sortData.map((ele) => {
                              return (
                                <li>
                                  <Button
                                    variant="link"
                                    onClick={() => handleSort(ele)}
                                  >
                                    {ele?.name}
                                  </Button>
                                </li>
                              );
                            })}
                          {/* <li>
                          <Button variant="link" onClick={() => handleSort("productNameAscending")}>
                            Product Name (Ascending)
                          </Button>
                        </li>
                        <li>
                          <Button variant="link" onClick={() => handleSort("productNameDescending")}>
                          Product Name (Descending)
                          </Button>
                        </li>
                        <li>
                          <Button variant="link" onClick={() => handleSort("priceAscending")}>
                            Price (Ascending)
                          </Button>
                        </li>
                        <li>
                          <Button variant="link" onClick={() => handleSort("priceDescending")}>
                          Price (Descending)
                          </Button>
                        </li> */}
                        </ul>
                      </Popover.Body>
                    </Popover>
                  </Overlay>
                </div>
              </div>
            </div>
            <div
              className="d-flex align-items-center justify-content-between flex-row mt-5"
              style={{ position: "relative" }}
            >
              <div style={{ width: "100%" }}>
                {/* <h3 style={styles.head}>{businessNumber}</h3> */}
                <Button
                  className="custom-btn"
                  variant="primary"
                  onClick={() => handleStatusFilter("")}
                >
                  All
                </Button>

                <Button
                  className="custom-btn"
                  variant="success"
                  onClick={() => handleStatusFilter("active")}
                >
                  Active
                </Button>
                <Button
                  className="custom-btn"
                  variant="warning"
                  onClick={() => handleStatusFilter("inactive")}
                >
                  Inactive
                </Button>
                <Button
                  className="custom-btn"
                  variant="danger"
                  onClick={() => handleStatusFilter("out_of_stock")}
                >
                  Out Of Stock
                </Button>
                <Button
                  className="custom-btn"
                  variant="secondary"
                  onClick={() => handleStatusFilter("low_stock")}
                >
                  Low Inventory
                </Button>
              </div>
            </div>

            <div style={styles.productTableWrap}>
              {/* <Table hover style={styles.productTable} className="productTable">
                <thead>
                  <tr>
                <th></th>
                <th></th>
                <th>Product Name</th>
                <th>Category</th>
                <th>Price</th>
                <th>Status</th>
                <th></th>
                </tr>
                  </thead>         <tbody>
                  {!loading && product.length === 0 ? (
                 
                    <NoDataFound title={t("commonHeader.noProductsFound")} />
                  ) : (
                    !loading &&
                    product.length !== 0 &&
                    product &&
                    product.map((item: any) => {
                      return (
                        <tr
                          key={item.id}
                          onClick={() => handleClick(item.id)}
                          style={{ cursor: "pointer" }}
                        >
                          <td className="hide-xs">
                            {" "}
                            <input
                              type="radio"
                              name="productSelect"
                              style={styles.productRadio}
                              disabled
                            />
                          </td>
                          <td>
                            <div style={styles.productImageWrap}>
                              <Image
                                src={`${base_url}/${item?.product_image}`}
                                style={styles.productImage}
                              />
                            </div>
                          </td>
                          <td style={styles.productInfo}>
                            {item?.language_data.length === 0
                              ? item?.product_name
                              : languageId === "ne"
                                ? item?.language_data[0]?.product_name
                                : item?.product_name}
                          </td>
                          <td style={styles.productInfo}>
                            {item?.product_subcategory?.language_data.length ===
                              0
                              ? item?.product_subcategory?.name
                              : languageId === "ne"
                                ? item?.product_subcategory?.language_data[0]
                                  ?.name
                                : item?.product_subcategory?.name}
                          </td>
                          <td style={styles.productInfo}>
                            ₹ {item?.PRDPrice}/-
                          </td>
                          <td
                            style={styles.productInfo}
                            className={getPRDISactiveStatus(item?.PRDISactive)}
                          >
                            {getPRDISactiveStatus(item?.PRDISactive)}
                          </td>
                          <td style={styles.productInfo}>
                            <FaAngleRight
                              onClick={() => handleClick(item.id)}
                              style={{ cursor: "pointer", fontSize: 24 }}
                            />
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table> */}
              <>
                {!loading && product.length === 0 ? (
                  <NoDataFound title={t("commonHeader.noProductsFound")} />
                ) : (
                  <div className="table-responsive">
                  <Table
                    hover
                    style={styles.productTable}
                    className="productTable"
                  >
                    <thead>
                      <tr>
                        <th className="hide-xs"></th>
                        <th></th>
                        <th>Product Name</th>
                        <th>Category</th>
                        <th>Price</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {!loading &&
                        product.length !== 0 &&
                        product.map((item: any) => (
                          <tr
                            key={item.id}
                            onClick={() => handleClick(item.id)}
                            style={{ cursor: "pointer" }}
                          >
                            <td className="hide-xs">
                              <input
                                type="radio"
                                name="productSelect"
                                style={styles.productRadio}
                                disabled
                              />
                            </td>
                            <td>
                              <div style={styles.productImageWrap}>
                                <Image
                                  src={`${base_url}/${item?.product_image}`}
                                  style={styles.productImage}
                                />
                              </div>
                            </td>
                            <td style={styles.productInfo}>
                              {item?.language_data.length === 0
                                ? item?.product_name
                                : languageId === "ne"
                                ? item?.language_data[0]?.product_name
                                : item?.product_name}
                            </td>
                            <td style={styles.productInfo}>
                              {item?.product_subcategory?.language_data
                                .length === 0
                                ? item?.product_subcategory?.name
                                : languageId === "ne"
                                ? item?.product_subcategory?.language_data[0]
                                    ?.name
                                : item?.product_subcategory?.name}
                            </td>
                            <td style={styles.productInfo}>
                              ₹ {item?.PRDPrice}/-
                            </td>
                            <td
                              style={styles.productInfo}
                              className={getPRDISactiveStatus(
                                item?.PRDISactive
                              )}
                            >
                              {getPRDISactiveStatus(item?.PRDISactive)}
                            </td>
                            <td style={styles.productInfo}>
                              <FaAngleRight
                                onClick={() => handleClick(item.id)}
                                style={{ cursor: "pointer", fontSize: 24 }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  </div>
                )}
                {loading && (
                  <Row>
                    <Col className="d-flex align-items-center justify-content-center">
                      <Spinner animation="grow" variant="primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </Col>
                  </Row>
                )}
              </>

              {!loading && product.length >= 10 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    float: "right",
                  }}
                  className="mobile-float-none"
                >
                  {(isSearchResult
                    ? pages <= 10 && product.length >= 10
                    : true) && (
                    <nav aria-label="Page navigation example">
                      <ul className="pagination">
                        <li
                          className={`page-item ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                        >
                          <a
                            className="page-link"
                            onClick={() =>
                              setCurrentPage(Math.max(currentPage - 1, 1))
                            }
                          >
                            {t("userProfile.previous")}
                          </a>
                        </li>
                        {Array.from({ length: pages }).map((_, index) => (
                          <li
                            className={`page-item ${
                              currentPage === index + 1 ? "active" : ""
                            }`}
                            key={index}
                          >
                            <a
                              className="page-link"
                              onClick={() => setCurrentPage(index + 1)}
                            >
                              {index + 1}
                            </a>
                          </li>
                        ))}
                        <li
                          className={`page-item ${
                            currentPage === pages ? "disabled" : ""
                          }`}
                        >
                          <a
                            className="page-link"
                            onClick={() =>
                              setCurrentPage(Math.min(currentPage + 1, pages))
                            }
                          >
                            {t("userProfile.next")}
                          </a>
                        </li>
                      </ul>
                    </nav>
                  )}
                </div>
              )}
            </div>
          </Container>
        </div>
      </Section>
    </>
  );
};

export default Products;
