import { initializeApp } from "firebase/app";
import { isSupported, getMessaging, Messaging } from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCEL_SJR7heME4upzAwWpRFd5CD9h3O-RM",
  authDomain: "easylife-507b5.firebaseapp.com",
  projectId: "easylife-507b5",
  storageBucket: "easylife-507b5.appspot.com",
  messagingSenderId: "81090566157",
  appId: "1:81090566157:web:0b3c826f67214bf15b9d44",
  measurementId: "G-775K4XTX2W",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

let messaging: Messaging | null = null; // Messaging type or null

(async () => {
  try {
    const supported = await isSupported(); // Check if messaging is supported
    if (supported) {
      messaging = getMessaging(app);
      console.log("Firebase Messaging is supported and initialized.");
    } else {
      console.warn("Firebase Messaging is not supported in this environment.");
    }
  } catch (error) {
    console.error("Error initializing Firebase Messaging:", error);
  }
})();

export { app, messaging };
