import { CSSProperties } from "react";
import TitleBg from "../../assets/images/title-bg.png";

export interface Styles {
  bg: CSSProperties;
  searchRow: CSSProperties;
  whiteWrap: CSSProperties;
  shopImageWrap: CSSProperties;
  shopImage: CSSProperties;
  shopNameWrap: CSSProperties;
  shopName: CSSProperties;
  head: CSSProperties;
  btnSort: CSSProperties;
  addBtn: CSSProperties;
  orderTableWrap:CSSProperties;
  orderTable:CSSProperties;
  orderInfo:CSSProperties;
  orderImageWrap:CSSProperties;
  orderImage:CSSProperties;
  orderRadio:CSSProperties;
  noProduct:CSSProperties;
  normalText:CSSProperties;
  orderStatus:CSSProperties;
  orderTitle:CSSProperties;
  input:CSSProperties;
  icon:CSSProperties;
  completed:CSSProperties;
}

const baseStyles: Styles = {
  bg: {
    backgroundImage: `url(${TitleBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    paddingTop: 40,
    paddingBottom: 100,
  },
  noProduct:{
    fontSize: 18,
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    fontFamily: "var(--primary-font-semibold)",
    backgroundColor: "var(--primary-color)",
    color:"#fff",
    padding:20,
    borderRadius:16
  },
  completed:{
    fontSize: 16,
    fontFamily: "var(--primary-font-semibold)",
    // color: "#4BAE4F",
    color:"red",
    // marginRight:12,
    // marginBottom:0
  },
  input:{
    borderWidth:0,
    fontSize:20,
    fontFamily:'var(--primary-font-medium)',
    color:"#AFABAB",
    paddingInline:20,
    paddingTop:10,
    paddingBottom:10,
    borderTopLeftRadius:16,
    borderBottomLeftRadius:16,
},
icon :{
    backgroundColor:"var(--white-color)",
    color:"#333333",
    fontSize:20,
    border:0,
    borderTopRightRadius:16,
    borderBottomRightRadius:16
},
  searchRow: {
    marginTop: 30,
    maxWidth: 1142,
    marginLeft: "auto",
    marginRight: "auto",
    position:"relative"
  },
  whiteWrap: {
    paddingLeft: 50,
    paddingRight: 50,
    backgroundColor: "var(--white-color)",
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    paddingTop: 60,
    paddingBottom: 60,
  },
  shopImageWrap: {
    width: 60,
    height: 60,
    overflow: "hidden",
  },
  shopImage: {
    width: "100%",
    objectFit: "contain",
    height: "100%",
  },
  shopNameWrap: {
    marginRight: 20,
  },
  shopName: {
    fontSize: 32,
    marginBottom: 0,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--text-color)",
  },
  head: {
    fontSize: 28,
    marginBottom: 0,
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
  },
  btnSort: {
    borderWidth: 0,
    backgroundColor: "var(--white-color)",
  },
  addBtn:{
    fontSize: 32,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--white-color)",
    backgroundColor:"var(--primary-color)",
    minWidth:400,
    marginTop:20,
    textAlign:"center"
  },
  orderTableWrap:{
    paddingTop:60,
    paddingBottom:60
  },
  orderTable:{},
  orderInfo:{
    fontSize: 18,
    fontFamily: "var(--primary-font-regular)",
    color: "var(--text-color)",
  },
  orderImageWrap:{
    width:100,
    height:100,
    overflow:"hidden",
    boxShadow: "0px 0px 2px 0px #00000040",
    borderWidth:1,
    borderStyle:"solid",
    borderRadius:10,
    borderColor:"#f5f5f5",
    padding:10,
    position:"relative"
  },
  orderImage:{
    width:"100%",
    height:"100%",
    objectFit:"contain"
  },
  orderRadio:{},
  normalText:{
    fontSize: 18,
    fontFamily: "var(--primary-font-regular)",
    color: "var(--text-color)",
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  },
  orderStatus:{
    fontSize: 20,
    fontFamily: "var(--primary-font-regular)",
    color: "var(--text-color)",
  },
  orderTitle:{
    fontSize: 20,
    marginBottom:5,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--text-color)",
  },
};

const mobileStyles: Partial<Styles> = {
  whiteWrap: {
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: "var(--white-color)",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  orderImageWrap:{
    width:50,
    height:50,
    overflow:"hidden",
    boxShadow: "0px 0px 2px 0px #00000040",
    borderWidth:1,
    borderStyle:"solid",
    borderRadius:12,
    borderColor:"#f5f5f5",
    padding:5
  },
  orderTableWrap:{
    paddingTop:20,
    paddingBottom:20
  },
  shopImageWrap: {
    width: 50,
    height: 50,
    overflow: "hidden",
  },
  shopName: {
    fontSize: 20,
    marginBottom: 0,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--text-color)",
  },
};
export const getStyles = (isMobile: boolean): Styles => {
  if (isMobile) {
    return {
      ...baseStyles,
      ...mobileStyles,
    };
  }
  return baseStyles;
};